import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import DropdownContextProvider from './v2/context/DropdownContext';
import { UserContext } from './v2/context/UserContext';
import CandidateSearchContextProvider from './v2/context/CandidateSearchContext';

// v2 Imports

import HomePage from './v2/pages/HomePage';
import AboutUs from './v2/pages/ContactPage';
import OurStoryv2 from './v2/pages/OurStory';
import JobDetails from './v2/pages/JobDetails';
import EmployerDashboard from './v2/pages/Employer/EmployerDashboard';
import AccountSettings from './v2/pages/Employer/AccountSettings';
import EmployerSavedProfiles from './v2/pages/Employer/EmployerSavedProfiles';
import EmployerManageJobs from './v2/pages/Employer/EmployerManageJobs';
import ContactPage from './v2/pages/ContactPage';
import SavedJobs from './v2/pages/Candidate/SavedJobs';
import AppliedJobs from './v2/pages/Candidate/AppliedJobs';
import CVManager from './v2/pages/Candidate/CVManager';
import ResumeGenerate from './v2/pages/Candidate/ResumeGenerate';
import FollowedCompanies from './v2/pages/Candidate/FollowedCompanies';
import CandidateChangePassword from './v2/pages/Candidate/CandidateChangePassword';

// import ReactGA from 'react-ga';
import EmployerPostJob from './v2/pages/Employer/EmployerPostJob';
import EmployerUpdatePostJob from './v2/pages/Employer/EmployerUpdatePostJob';
import Signup from './v2/pages/Signup';
import DashboardRouting from './v2/pages/DashboardRouting';

import SearchJob from './v2/pages/Search/SearchJob';
import SearchCandidate from './v2/pages/Search/SearchCandidate';
import AppliedCandidateSearch from './v2/pages/Search/AppliedCandidateSearch';
import EmployerUpdateProfile from './v2/pages/Employer/EmployerUpdateProfile';
import CandidateProfile from './v2/pages/Candidate/CandidateProfile';
import CandidateUpdateProfile from './v2/pages/Candidate/CandidateUpdateProfile';
import ExploreCategories from './v2/pages/ExploreCategories';
import AppliedCandidateProfile from './v2/pages/Candidate/AppliedCandidateProfile';
import PrivacyPolicyPage from './v2/pages/PrivacyPolicyPage';
import RefundPolicyPage from './v2/pages/RefundPolicyPage';
import TermsAndConditionsPage from './v2/pages/TermsAndConditionsPage';

import EmployerRoute from './v2/contextRoutes/EmployerRoute';
import EmployerContextProvider from './v2/context/EmployerContext';

import JobSearchRoute from './v2/contextRoutes/JobSearchRoute';

import AuthRoute from './v2/contextRoutes/AuthRoute';
import CandidateRoute from './v2/contextRoutes/CandidateRoute';
import PaymentSuccess from './v2/pages/PaymentSuccess';
import PaymentFailed from './v2/pages/PaymentFailed';
import Packages from './v2/pages/Packages';

// Payment Pages
import Checkout from './v2/pages/Payment/Checkout';
import EmployerPurchaseHistory from './v2/pages/Employer/EmployerPurchaseHistory';

import PageNotFound from './v2/pages/PageNotFound';
import CandidateNotifications from './v2/pages/Candidate/CandidateNotifications';
import EmployerNotifications from './v2/pages/Employer/EmployerNotifications';

import PasswordReset from './v2/pages/PasswordReset';
import UserJourneyAlert from './v2/components/Alerts/UserJourneyAlert';
import ProfileStrengthAlert from './v2/components/Alerts/ProfileStrengthAlert';
import ProfileStrengthContextProvider from './v2/context/ProfileStrengthContext';
import MaintenanceMode from './v2/components/MaintenanceMode';
import UpdateStepper from './v2/components/Registration/CandidateFlow/UpdateStepper';
import Headerv2 from './v2/components/Headerv2';
import CandidateRegister from './v2/pages/CandidateRegister';
import EmployerRegister from './v2/pages/EmployerRegister';
import Loginv2 from './v2/pages/Loginv2';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AdminLogin from './v2/pages/Admin/AdminLogin';
import SpecialPage from './v2/pages/SpecialPage';
import PageBuilder from './v2/pages/Admin/PageBuilder';
import CompanyList from './v2/pages/CompanyList';
import SearchJobV2 from './v2/pages/Search/SearchJobV2';
import EmployerProfilev2 from './v2/pages/Employer/EmployerProfilev2';
import Messages from './v2/pages/Messages';
import CandidateMessagePage from './v2/pages/Candidate/CandidateMessagePage';
import EmployerMessagePage from './v2/pages/Employer/EmployerMessagePage';
import RecommendedJobs from './v2/pages/Candidate/RecommendedJobs';
// import AppliedCandidateSearchV2 from './v2/pages/Search/AppliedCandidateSearchV2';
import ScrollableTabAndTabScreen from './v2/pages/ScrollableTabAndTabScreen/ScrollableTabAndTabScreen';
import { useTranslation } from 'react-i18next';
import SearchCXOJobs from './v2/pages/Search/CxoJobs';
import CXOJobDetails from './v2/pages/CXOJobDetails';
import CandidateDashboard from './v2/pages/Candidate/CandidateDashboard';
import LP1 from './v2/pages/LandingPages/LP1';
import LP2 from './v2/pages/LandingPages/LP2';
import StaticPage from './v2/components/StaticPage';
import LP3 from './v2/pages/LandingPages/LP3';
import LP4 from './v2/pages/LandingPages/LP4';
import Lp5 from './v2/pages/LandingPages/Lp5';
import LP6 from './v2/pages/LandingPages/LP6';
import LP7 from './v2/pages/LandingPages/LP7';
import AppliedCandidateSearchV3 from './v2/pages/Search/AppliedCandidateSearchV3';
import ShortFooter from './v2/components/ShortFooter';
import ResumeGeneratev2 from './v2/pages/Candidate/ResumeGenerateV2';
import FAQsPage from './v2/pages/FaqPage';
import LongFooter from './v2/components/LongFooter';
import JobDetailsV2 from './v2/pages/JobDetailsV2';
import DrInboxPage from './v2/pages/Candidate/dr/inbox/DrInboxPage';

// const TRACKING_ID = "UA-221293204-1"; // OUR_TRACKING_ID

// ReactGA.initialize(TRACKING_ID);

const theme = createTheme({
    typography: {
        h1: {
            fontFamily: 'Nunito, sans-serif',
            fontSize: '48px',
            fontWeight: 700,
        },
        h2: {
            fontFamily: 'Nunito, sans-serif',
            fontSize: '40px',
            fontWeight: 700,
        },
        h3: {
            fontFamily: 'Nunito, sans-serif',
            fontSize: '33px',
            fontWeight: 700,
        },
        h4: {
            fontFamily: 'Nunito, sans-serif',
            fontSize: '28px',
            fontWeight: 700,
        },
        h5: {
            fontFamily: 'Nunito, sans-serif',
            fontSize: '23px',
            fontWeight: 700,
        },
        h6: {
            fontFamily: 'Nunito, sans-serif',
            fontSize: '19px',
            fontWeight: 700,
        },
        body1: {
            fontFamily: 'Poppins, sans-serif',
        },
        body2: {
            fontFamily: 'Poppins, sans-serif',
        },
        caption: {
            fontFamily: 'Poppins, sans-serif',
        },
    },
});

const Markup = () => {
    const { getUserNotification, isBackEndDown, isLoggedin } = useContext(
        UserContext
    );
    const location = useLocation();
    const { i18n } = useTranslation();

    const [footer, setFooter] = useState({
        isHome: false,
        isPublicPath: false,
        isLoginPublicPath: false
    })

    const publicPaths = ["/contact-us", "/login", "/candidate-register", "/privacy-policy", "/refund-policy", "/terms-conditions", "/faq", "/our-story"];
    const loginPublicPaths = ["/search/jobs", "/search/cxo-jobs", "/jobs/", "/cxo-jobs/"];

    useEffect(() => {
        if (isLoggedin) {
            getUserNotification();
        }
        window.scrollTo(0, 0);
        if (location.pathname !== '/') {
            i18n.changeLanguage('en');
        }

        const isLoginPath = loginPublicPaths.some(path => location.pathname.startsWith(path));
        setFooter({ ...footer, isHome: location.pathname === "/", isPublicPath: publicPaths.includes(location.pathname), isLoginPublicPath: isLoginPath })

    }, [location.pathname]);

    // useEffect(() => {
    //     ReactGA.pageview(window.location.pathname + window.location.search);
    // }, []);

    return (
        <DropdownContextProvider>
            <ProfileStrengthContextProvider>
                <ProfileStrengthAlert />
                {isBackEndDown ? (
                    <MaintenanceMode />
                ) : (
                    <div className="page-wraper">
                        {/* <CustomHeader /> */}
                        <Headerv2 />
                        <UserJourneyAlert />
                        <Switch>
                            {/* V2 routes */}
                            <Route
                                path="/lp/candidate/new"
                                exact
                                component={StaticPage}
                            />
                            <Route path="/register" exact component={Signup} />
                            <Route
                                path="/scroll-menu"
                                exact
                                component={ScrollableTabAndTabScreen}
                            />
                            <Route path="/about-us" exact component={AboutUs} />
                            <Route
                                path="/our-story"
                                exact
                                component={OurStoryv2}
                            />
                            <Route
                                path="/contact-us"
                                exact
                                component={ContactPage}
                            />
                            <Route
                                path="/faq"
                                exact
                                component={FAQsPage}
                            />
                            <Route
                                path="/privacy-policy"
                                exact
                                component={PrivacyPolicyPage}
                            />
                            <Route
                                path="/refund-policy"
                                exact
                                component={RefundPolicyPage}
                            />
                            <Route
                                path="/terms-conditions"
                                exact
                                component={TermsAndConditionsPage}
                            />

                            <Route
                                path="/company/list"
                                exact
                                component={CompanyList}
                            />

                            {/* Common Routes */}
                            <AuthRoute
                                path="/candidate-message"
                                exact
                                component={CandidateMessagePage}
                            />

                            {/* <AuthRoute
                                path="/dr-inbox"
                                exact
                                component={DrInboxPage}
                            /> */}

                            {/* Employer Routes */}

                            <AuthRoute
                                path="/employer-update-profile"
                                exact
                                component={EmployerUpdateProfile}
                            />
                            {/* <AuthRoute
                                path="/applicants/job/:job_id"
                                exact
                                component={AppliedCandidateSearchV2}
                            /> */}
                            <AuthRoute
                                path="/employer-dashboard"
                                exact
                                component={EmployerDashboard}
                            />
                            <AuthRoute
                                path="/employer-saved-profiles"
                                exact
                                component={EmployerSavedProfiles}
                            />
                            <AuthRoute
                                path="/employer-manage-jobs"
                                exact
                                component={EmployerManageJobs}
                            />
                            <AuthRoute
                                path="/employer-post-job"
                                exact
                                component={EmployerPostJob}
                            />
                            <AuthRoute
                                path="/employer-update-job/:job_id"
                                exact
                                component={EmployerUpdatePostJob}
                            />
                            <AuthRoute
                                path="/employer-notifications"
                                exact
                                component={EmployerNotifications}
                            />
                            <AuthRoute
                                path="/job/:job_id/applicant/:candidate_id"
                                exact
                                component={AppliedCandidateProfile}
                            />
                            <AuthRoute
                                path="/employer-message"
                                exact
                                component={EmployerMessagePage}
                            />

                            {/* Candidate Routes */}
                            <AuthRoute
                                path="/candidate/profile/:slug"
                                exact
                                component={CandidateProfile}
                            />

                            {/* AuthRoutes */}
                            <AuthRoute
                                path="/candidate-dashboard"
                                exact
                                component={CandidateDashboard}
                            />
                            <AuthRoute
                                path="/candidate-update-profile"
                                exact
                                component={CandidateUpdateProfile}
                            />
                            <AuthRoute
                                path="/cv-manager"
                                exact
                                component={CVManager}
                            />
                            <AuthRoute
                                path="/generate-resume"
                                exact
                                component={ResumeGeneratev2}
                            />
                            <AuthRoute
                                path="/applied-jobs"
                                exact
                                component={AppliedJobs}
                            />
                            <AuthRoute
                                path="/saved-jobs"
                                exact
                                component={SavedJobs}
                            />
                            <AuthRoute
                                path="/recommended-jobs"
                                exact
                                component={RecommendedJobs}
                            />
                            <AuthRoute
                                path="/followed-companies"
                                exact
                                component={FollowedCompanies}
                            />
                            <AuthRoute
                                path="/messages/:from_id"
                                exact
                                component={Messages}
                            />
                            <Route
                                path="/password/reset/:token/:email"
                                component={PasswordReset}
                            />
                            <AuthRoute
                                path="/applicants/job/:job_id"
                                exact
                                component={AppliedCandidateSearchV3}
                            />
                            <AuthRoute
                                path="/employer-update-profile"
                                exact
                                component={EmployerUpdateProfile}
                            />

                            {/* Candidate Registration Flow */}
                            <AuthRoute
                                path="/candidate-register/update"
                                component={UpdateStepper}
                            />

                            <ThemeProvider theme={theme}>
                                <Switch>
                                    <Route
                                        path="/login"
                                        exact
                                        component={Loginv2}
                                    />

                                    <Route
                                        path="/admin"
                                        exact
                                        component={AdminLogin}
                                    />
                                    <Route
                                        path="/special-page/:slug"
                                        component={SpecialPage}
                                    />
                                    <AuthRoute
                                        path="/page-builder"
                                        exact
                                        component={PageBuilder}
                                    />
                                    <Route
                                        path="/candidate-register"
                                        exact
                                        component={CandidateRegister}
                                    />
                                    <Route
                                        path="/employer-register"
                                        exact
                                        component={EmployerRegister}
                                    />

                                    {/* V2 Search Pages  */}
                                    <JobSearchRoute
                                        path="/search/jobs"
                                        exact
                                        component={SearchJobV2}
                                    />
                                    <JobSearchRoute
                                        path="/jobs/:job_id/:slug"
                                        exact
                                        component={JobDetailsV2}
                                    />
                                    <JobSearchRoute
                                        path="/jobs/:job_id"
                                        exact
                                        component={JobDetailsV2}
                                    />
                                    <JobSearchRoute
                                        path="/search/cxo-jobs"
                                        exact
                                        component={SearchCXOJobs}
                                    />
                                    <JobSearchRoute
                                        path="/cxo-jobs/:job_id"
                                        exact
                                        component={JobDetailsV2}
                                    />
                                    <JobSearchRoute
                                        path="/cxo-jobs/:job_id/:slug"
                                        exact
                                        component={JobDetailsV2}
                                    />
                                    <JobSearchRoute
                                        path="/employer/profile/:slug"
                                        exact
                                        component={EmployerProfilev2}
                                    />
                                    <JobSearchRoute
                                        path="/"
                                        exact
                                        component={HomePage}
                                    />
                                    <JobSearchRoute
                                        path="/all-categories"
                                        exact
                                        component={ExploreCategories}
                                    />

                                    {/* Candidate Search section to be temporarily off */}
                                    {/* <Route path='/search/candidates' exact>
                                            <CandidateSearchContextProvider>
                                                <EmployerContextProvider>
                                                    <SearchCandidate />
                                                </EmployerContextProvider>
                                            </CandidateSearchContextProvider>
                                        </Route> */}

                                    {/* AuthRoutes */}
                                    <AuthRoute
                                        path="/candidate-notifications"
                                        exact
                                        component={CandidateNotifications}
                                    />

                                    {/* common routes */}
                                    <Route
                                        path="/dashboard"
                                        exact
                                        component={DashboardRouting}
                                    />
                                    <AuthRoute
                                        path="/candidate-change-password"
                                        exact
                                        component={CandidateChangePassword}
                                    />
                                    <AuthRoute
                                        path="/employer-account-settings"
                                        exact
                                        component={AccountSettings}
                                    />
                                    <JobSearchRoute
                                        path="/lp/home"
                                        exact
                                        component={LP1}
                                    />
                                    <Route
                                        path="/lp2/home"
                                        exact
                                        component={LP2}
                                    />
                                    <Route
                                        path="/lp3/home"
                                        exact
                                        component={LP3}
                                    />
                                    <Route
                                        path="/lp4/home"
                                        exact
                                        component={LP4}
                                    />
                                    <Route
                                        path="/lp5/home"
                                        exact
                                        component={Lp5}
                                    />
                                    <Route
                                        path="/lp6/home"
                                        exact
                                        component={LP6}
                                    />
                                    <Route
                                        path="/lp7/home"
                                        exact
                                        component={LP7}
                                    />

                                    <Route path="*" component={PageNotFound} />
                                </Switch>
                            </ThemeProvider>
                        </Switch>
                        {(footer.isHome || footer.isPublicPath) && <LongFooter />}
                        {(footer.isLoginPublicPath && isLoggedin) && <ShortFooter />}
                        {(footer.isLoginPublicPath && (!isLoggedin)) && <LongFooter />}
                    </div>
                )}
            </ProfileStrengthContextProvider>
        </DropdownContextProvider>
    );
};

export default Markup;
