import React, { createContext, useEffect, useState } from "react"
import Cookies from 'js-cookie'
import { getProfilePic, uploadProfilePic, getNotifications, updateNotification, deleteNotification, postLogout, getHealthCheck } from "../../fetchAPI";
import ErrorAlert from '../components/Alerts/ErrorAlert'
import { useAlert } from "./AlertContext";

export const UserContext = createContext();

const UserContextProvider = (props) => {

  const [userData, setUserData] = useState('');
  const [isLoggedin, setIsLoggedin] = useState(Cookies.get('user') ? true : false);
  const [notifications, setNotifications] = useState([])
  const [notificationsLoading, setNotificationsLoading] = useState(false)
  const [paginationInfo, setPaginationInfo] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [notificationCounter, setNotificationCounter] = useState(0)

  const [applyAttemptJobInfo, setApplyAttemptJobInfo] = useState({})
  const [applyAlertNotificationOpen, setApplyAlertNotificationOpen] = useState(false)

  const [redirectionLink, setRedirectionLink] = useState('/dashboard')

  const [promotionalMobileAlertOpen, setPromotionalMobileAlertOpen] = useState(false)
  const [promotionalWebAlertOpen, setPromotionalWebAlertOpen] = useState(false)
  const [promotionalAlertOpen, setPromotionalAlertOpen] = useState(false)

  // to check backend server 
  const [isBackEndDown, setIsBackEndDown] = useState(false)

  const alertContext = useAlert();

  // useEffect(() => {
  //   const token = Cookies.get('token')
  //   if (token) {
  //     setIsLoggedin(true)
  //   } else {
  //     setIsLoggedin(false)
  //   }
  // }, [])

  const settingUserData = () => {
    setUserData(JSON.parse(Cookies.get('user')))
  }

  useEffect(() => {
    if (isLoggedin) {
      settingUserData()
    }
  }, [isLoggedin])

  const loginData = (result) => {
    if (result.response.data.token !== undefined) {
      Cookies.set('token', result.response.data.token, { secure: true, sameSite: 'strict' })
      Cookies.set('user', result.response.data.user, { secure: true, sameSite: 'strict' })
      setIsLoggedin(true)
    } else {
      console.log("Login token could not be saved")
    }
  };


  const logout = () => {

    postLogout({ platform: "web" }).then((response) => {
      if (response && response.success) {
        Cookies.remove('token')
        Cookies.remove('user')
        setIsLoggedin(false)
        sessionStorage.removeItem('modalShown');
        localStorage.removeItem("stepper_step");
        window.location.replace('/login')
      }
      else {
        console.log('errors:', response);
      }
    })
  }


  /**
   * Profile Picture Upload / render
   */

  const [profilePic, setProfilePic] = useState('')
  const [uploadSuccess, setUploadSuccess] = useState(1)

  useEffect(() => {
    if (isLoggedin) {
      getProfilePic()
        .then(response => {
          if (response && response.success) {
            setProfilePic(response?.response.data)
          }
        })
    }

  }, [isLoggedin, uploadSuccess])

  // For upload profile photo
  const [selectedFile, setSelectedFile] = useState('')
  const [loading, setLoading] = useState(false)
  const [successTwister, setSuccessTwister] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [uploadErrorAlert, setUploadErrorAlert] = useState(false)
  const [uploadErrorMessage, setUploadErrorMessage] = useState('')

  const upLoadProfilePicture = () => {
    setLoading(true)
    const formData = new FormData();
    formData.append('profile_photo', selectedFile);

    if (selectedFile.size < 2000000) {
      uploadProfilePic(formData)
        .then(response => {
          if (response && response.success) {
            setLoading(false)
            setUploadSuccess(prevValue => prevValue + 1)
            alertContext.setResponseMessage(response.response.message);
            alertContext.setOpenAlert(true);

          } else {
            setLoading(false)
            alertContext.setResponseMessage(response.error.profile_photo);
            alertContext.setOpenErrorAlert(true);

          }
        })
    } else {
      setLoading(false)
      alertContext.setResponseMessage('File size is larger than 2MB');
      alertContext.setOpenErrorAlert(true);

    }
  }

  useEffect(() => {
    if (selectedFile) {
      upLoadProfilePicture()
    }
  }, [selectedFile])

  const getUserNotification = async () => {
    setNotificationsLoading(true);
    try {
      const response = await getNotifications(userData.id, currentPage);
      if (response && response.success) {
        setNotifications(response.response.data);
        setPaginationInfo(response.response.pagination)
      } else {
        console.log('Failed to fetch notifications:', response);
      }
    } catch (error) {
      // setIsBackEndDown(true);
    } finally {
      setNotificationsLoading(false);
    }
  };

  // HealthCheck disabled temporarily
  
  // const getHealthCheckAPI = async () => {
  //   try {
  //     const response = await getHealthCheck();
  //     if (response && response.status == 'ok') {
  //       return;
  //     } else {
  //       alertContext.setResponseMessage(response.errors[0].code + ': Health Check: ' + response.errors[0].message);
  //       alertContext.setOpenErrorAlert(true);
  //     }
  //   } catch (error) {
  //     setIsBackEndDown(true);
  //   }
  // };

  // useEffect(() => {
  //   getHealthCheckAPI()
  // }, [])

  // Notification counter based on Unread notifications
  const countUnreadNotifications = () => {
    const unreadNotification = notifications?.filter(notification => notification.read_status === 0)
    setNotificationCounter(unreadNotification.length)
  }
  useEffect(() => {
    countUnreadNotifications()
  }, [notifications])

  const changeNotificationReadStatus = (notificationUpdateData) => {

    updateNotification(userData.id, notificationUpdateData).then((response) => {
      if (response && response.success) {
        // console.log('Success :' ,response)
        getUserNotification()
      }
    })
      .catch(error => {
        console.log('Error Response', error)
      })
  }

  const deleteNotificationByUserId = (notificationDeleteData) => {

    deleteNotification(userData.id, notificationDeleteData).then((response) => {
      if (response && response.success) {
        // console.log('Success :' ,response)
        getUserNotification()
      }
    })
      .catch(error => {
        console.log('Error Response', error)
      })
  }

  // useEffect(() => {
  //   if (applyAttemptJobInfo.job_id) {
  //     setRedirectionLink(`jobs/${applyAttemptJobInfo.job_id}`)
  //   } else {
  //     setRedirectionLink('dashboard')
  //   }
  // }, [applyAttemptJobInfo])

  return (
    <UserContext.Provider value={{
      userData,
      setUserData,
      loginData,
      settingUserData,
      logout,
      isLoggedin,
      profilePic,
      loading,
      successTwister,
      successMessage,
      selectedFile, setSelectedFile,
      loading, setLoading,
      successTwister, setSuccessTwister,
      successMessage, setSuccessMessage,
      notifications,
      notificationsLoading,
      paginationInfo,
      setCurrentPage,
      currentPage,
      changeNotificationReadStatus,
      deleteNotificationByUserId,
      notificationCounter,
      getUserNotification,
      applyAttemptJobInfo, setApplyAttemptJobInfo,
      applyAlertNotificationOpen, setApplyAlertNotificationOpen,

      redirectionLink, setRedirectionLink,

      promotionalAlertOpen, setPromotionalAlertOpen,

      promotionalMobileAlertOpen, setPromotionalMobileAlertOpen,
      promotionalWebAlertOpen, setPromotionalWebAlertOpen,

      uploadErrorAlert, setUploadErrorAlert,
      uploadErrorMessage, setUploadErrorMessage,
      isBackEndDown
    }}>
      {props.children}
    </UserContext.Provider>
  );

};

export default UserContextProvider;