import React, { useState } from "react";
import { Box, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import GlobalLoader from "../../GlobalLoader";
import NoResultsFound from "../../NoResultsFound";

function InterviewContent(props) {
    return (
        <Box id={props?.id}>
            <Typography sx={{
                fontFamily: "Poppins",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "30px",
                color: "#1B1B1F",
                my: 3
            }}>
                {props?.title}
            </Typography>
            <Box sx={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "28px",
                color: "#1B1B1F",
                my: 3
            }}>
                {props?.content}
            </Box>
        </Box>
    )
}

function SingleInterviewInformation(props) {
    const [selectedItem, setSelectedItem] = useState(null);

    const handleItemClick = (id) => {
        setSelectedItem(id);
        scrollToSection(id);
    };

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            window.scrollTo({
                top: element.offsetTop - 120,
                behavior: 'smooth',
            });
        }
    };

    return (
        props?.loading ?
            <GlobalLoader height="70vh" />
            :
            <Box sx={{ px: { xs: 0, md: 8 }, minHeight: '100vh' }}>
                {props?.interviews?.contents?.length > 0 ?
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={9}>
                            <Box>
                                {props?.interviews?.banner_img_base64 ?
                                    <img src={props?.interviews?.banner_img_base64} width="100%" height="100%" alt="interview" />
                                    :
                                    <img src={'/v2/images/interview-static.png'} width="100%" height="100%" alt="interview" />
                                }
                                <Typography sx={{
                                    fontFamily: "Poppins",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    lineHeight: "28px",
                                    color: "#1B1B1F",
                                    my: 3
                                }}>
                                    {props?.interviews?.summary}
                                </Typography>
                            </Box>

                            {/* table of content for small screen */}
                            <Box sx={{ padding: 2, display: { xs: "block", md: "none" } }}>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    sx={{
                                        color: "#1B1B1F",
                                        fontFamily: "Raleway",
                                        fontSize: "20px",
                                        lineHeight: "23.48px",
                                        fontWeight: 600,
                                    }}
                                >
                                    Table Of Content
                                </Typography>
                                <List component="nav">
                                    {props?.interviews?.contents?.map((section) => (
                                        <ListItem
                                            button
                                            key={section.id}
                                            onClick={() => handleItemClick(section.id)}
                                            sx={{
                                                borderLeft: selectedItem === section.id ? '4px solid #0079C1' : '4px solid transparent', // Highlight border
                                                transition: 'border-left 0.3s ease',
                                                color: selectedItem === section.id ? "#0079C1" : "#1B1B1F",
                                                fontFamily: "Raleway",
                                                fontSize: "16px",
                                                lineHeight: "18.78px",
                                                fontWeight: selectedItem === section.id ? 600 : 400,
                                            }}
                                        >
                                            <ListItemText primary={section.title} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>


                            {/* Content  */}
                            <Grid container>
                                {props?.interviews?.contents?.map((item) => {
                                    return (
                                        <Grid item xs={12} key={item.id}>
                                            <InterviewContent id={item?.id} title={item?.title} content={item?.content} />
                                        </Grid>
                                    )
                                })}
                            </Grid>

                        </Grid>

                        <Grid item xs={12} md={3} display={{ xs: "none", md: "block" }}>
                            <Box sx={{ padding: 2 }}>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    sx={{
                                        color: "#1B1B1F",
                                        fontFamily: "Raleway",
                                        fontSize: "20px",
                                        lineHeight: "23.48px",
                                        fontWeight: 600,
                                    }}
                                >
                                    Table Of Content
                                </Typography>
                                <List component="nav">
                                    {props?.interviews?.contents?.map((section) => (
                                        <ListItem
                                            button
                                            key={section.id}
                                            onClick={() => handleItemClick(section.id)}
                                            sx={{
                                                borderLeft: selectedItem === section.id ? '4px solid #0079C1' : '4px solid transparent', // Highlight border
                                                transition: 'border-left 0.3s ease',
                                                color: selectedItem === section.id ? "#0079C1" : "#1B1B1F",
                                                fontFamily: "Raleway",
                                                fontSize: "16px",
                                                lineHeight: "18.78px",
                                                fontWeight: selectedItem === section.id ? 600 : 400,
                                            }}
                                        >
                                            <ListItemText primary={section.title} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        </Grid>
                    </Grid>
                    :
                    <NoResultsFound />
                }
            </Box>
    )
}

export default SingleInterviewInformation