import React, { useContext } from "react";
import { Box, Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { ArrowForwardIosOutlined } from "@mui/icons-material";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import parse from "react-html-parser";
import { useDrInbox } from "../contexts/DrInboxContextProvider";
import styles from "./MessageBox.module.css";
import { useAlert } from "../../../../../context/AlertContext";
import { UserContext } from "../../../../../context/UserContext";
import { LoadingButton } from "@mui/lab";

function MessageBox() {
  const { userData, profilePic } = useContext(UserContext);

  const alertContext = useAlert();
  const messageBody = useRef();
  const { state, dispatch, sendMessage } = useDrInbox();

  // console.log(userData);

  useEffect(() => {
    dispatch({ type: "setMessageBodyRef", messageBodyRef: messageBody });
  }, [messageBody]);

  const fileInput = useRef(null);
  const [messageInputValue, setMessageInputValue] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");

  // useEffect(() => {
  //   if (unseenMessages.length > 0) {
  //     let formData = new FormData();
  //     formData.append("message_ids", unseenMessages);
  //     readMessages(formData).then((response) => {
  //       if (response.success) {
  //         setUnseenMessages([]);
  //       }
  //     });
  //   }
  // }, [unseenMessages]);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (
      scrollTop == 0 &&
      !state.messageLoading &&
      !state.chatIdChanging &&
      state.messages[state.currentChatId].length > 0
    ) {
      // console.log("here2");
      dispatch({ type: "getPrevMessage" });
    }
  };

  const handleRefresh = (e) => {
    if (state.currentChatId != null) {
      dispatch({ type: "getNextMessage" });
    }
  };

  const handleFileClose = (e) => {
    setSelectedFileName("");
    fileInput.current.value = null;
  };

  const formSubmit = (e) => {
    e.preventDefault();

    if (state.currentChatId == null) {
      alertContext.setResponseMessage(
        "Please select a recipient to send a message"
      );
      alertContext.setOpenErrorAlert(true);
      return;
    }

    if (!messageInputValue && !fileInput.current.value) {
      alertContext.setResponseMessage(
        "Please type message or attach a file to send message."
      );
      alertContext.setOpenErrorAlert(true);
      return;
    }

    let formData = new FormData();
    formData.append("message", messageInputValue);
    formData.append("chatId", state.currentChatId);

    if (fileInput.current.files.length > 0) {
      formData.append("attachment", fileInput.current.files[0]);
    }

    sendMessage(formData).then((response) => {
      if (response && response.success) {
        setMessageInputValue("");
        setSelectedFileName("");
        fileInput.current.value = null;

        messageBody.current.scroll({
          top: messageBody.current.scrollHeight,
          behavior: "smooth",
        });
      }
    });
  };

  const attachmenSvg = (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2982 7.53531L8.67362 14.1599C7.86208 14.9715 6.76136 15.4275 5.61364 15.4275C4.46591 15.4275 3.36519 14.9715 2.55363 14.1599C1.74206 13.3484 1.28613 12.2476 1.28613 11.0999C1.28613 9.95217 1.74206 8.8515 2.55363 8.0399L9.17821 1.41531C9.71928 0.874266 10.4531 0.570312 11.2182 0.570312C11.9834 0.570312 12.7172 0.874266 13.2582 1.41531C13.7993 1.95635 14.1033 2.69017 14.1033 3.45531C14.1033 4.22046 13.7993 4.95427 13.2582 5.49532L6.62643 12.1199C6.35591 12.3904 5.989 12.5424 5.60643 12.5424C5.22385 12.5424 4.85695 12.3904 4.58643 12.1199C4.31591 11.8494 4.16392 11.4825 4.16392 11.0999C4.16392 10.7174 4.31591 10.3504 4.58643 10.0799L10.7064 3.96711"
        stroke="#72737C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const attachmenSvgWhite = (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2982 7.53531L8.67362 14.1599C7.86208 14.9715 6.76136 15.4275 5.61364 15.4275C4.46591 15.4275 3.36519 14.9715 2.55363 14.1599C1.74206 13.3484 1.28613 12.2476 1.28613 11.0999C1.28613 9.95217 1.74206 8.8515 2.55363 8.0399L9.17821 1.41531C9.71928 0.874266 10.4531 0.570312 11.2182 0.570312C11.9834 0.570312 12.7172 0.874266 13.2582 1.41531C13.7993 1.95635 14.1033 2.69017 14.1033 3.45531C14.1033 4.22046 13.7993 4.95427 13.2582 5.49532L6.62643 12.1199C6.35591 12.3904 5.989 12.5424 5.60643 12.5424C5.22385 12.5424 4.85695 12.3904 4.58643 12.1199C4.31591 11.8494 4.16392 11.4825 4.16392 11.0999C4.16392 10.7174 4.31591 10.3504 4.58643 10.0799L10.7064 3.96711"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const renderMessages = () => {
    const messages = state.currentChatId
      ? state.messages[state.currentChatId]
      : [];

    let groupMessages = [];
    let g = -1;
    let me = userData?.id;
    let currentFromId = -1;

    for (let i = 0; i < messages.length; i++) {
      if (messages[i].user_id != currentFromId) {
        currentFromId = messages[i].user_id;
        g++;
        groupMessages[g] = {};
        if (currentFromId == me) {
          groupMessages[g].direction = "right";
          groupMessages[g].messages = [];
          groupMessages[g].initId = messages[i].id;
        } else {
          groupMessages[g].direction = "left";
          groupMessages[g].messages = [];
          groupMessages[g].initId = messages[i].id;
        }
      }

      groupMessages[g].messages.push(messages[i]);
    }

    return groupMessages.map((item) => {
      return item.direction == "left" ? (
        <div className={styles.messageSenderContainer} key={item.initId}>
          <div className={styles.userPicContainer}>
            <img
              src={
                state.currentChatParticipants[0]?.pic
                  ? state.currentChatParticipants[0]?.pic
                  : "/v2/images/candidate-default.png"
              }
            />
          </div>
          <div className={styles.senderMessages}>
            {item.messages.map((mitem) => {
              if (mitem.attachment != null) {
                let attachmentNameArray = mitem.attachment.split("/");
                let attachmentName =
                  attachmentNameArray[attachmentNameArray.length - 1];
                return (
                  <div key={mitem.id}>
                    {mitem.content ? (
                      <div className={styles.senderSingleMessage}>
                        {parse(mitem.content)}
                      </div>
                    ) : (
                      ""
                    )}

                    <div className={styles.senderSingleMessage}>
                      <a href={mitem.attachment} target="_blank">
                        {attachmenSvg} {attachmentName}
                      </a>
                    </div>
                  </div>
                );
              }
              return (
                <div key={mitem.id} className={styles.senderSingleMessage}>
                  {parse(mitem.content)}
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className={styles.messageOwnContainer} key={item.initId}>
          <div className={styles.ownMessages}>
            {item.messages.map((mitem) => {
              if (mitem.attachment != null) {
                let attachmentNameArray = mitem.attachment.split("/");
                let attachmentName =
                  attachmentNameArray[attachmentNameArray.length - 1];
                return (
                  <div key={mitem.id}>
                    {mitem.content ? (
                      <div className={styles.ownSingleMessageContainer}>
                        <div className={styles.ownSingleMessage}>
                          {parse(mitem.content)}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className={styles.ownSingleMessageContainer}>
                      <div className={styles.ownSingleMessage}>
                        <a href={mitem.attachment} target="_blank">
                          {attachmenSvgWhite} {attachmentName}
                        </a>
                      </div>
                    </div>
                  </div>
                );
              }
              return (
                <div
                  key={mitem.id}
                  className={styles.ownSingleMessageContainer}
                >
                  <div className={styles.ownSingleMessage}>
                    {parse(mitem.content)}
                  </div>
                </div>
              );
            })}
          </div>
          <div className={styles.userPicContainer}>
            <img
              src={profilePic ? profilePic : "/v2/images/candidate-default.png"}
            />
          </div>
        </div>
      );
    });
  };

  return (
    <Box
      sx={{
        px: {
          xs: "0%",
          md: "3%",
          zIndex: 20,
          position: "relative",
        },
        mt: {
          xs: 0,
          md: "30px",
        },
      }}
    >
      <Box
        className={styles.messageBox}
        sx={{
          background: "white",
          borderColor: "#d2d2d2 !important",
        }}
      >
        <Box
          className={styles.messageBoxHeader}
          sx={{
            background: "#E5F5FF",
            boxShadow: 1,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              className="text-truncate"
              style={{ display: "flex", alignItems: "center", gap: "4px" }}
            >
              <div
                className=" bg-white"
                style={{
                  width: "45px",
                  height: "45px",
                  borderRadius: "50%",
                  padding: "3px",
                }}
              >
                <img
                  style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                  src={
                    state.currentChatParticipants[0]?.pic ||
                    "/v2/images/candidate-default.png"
                  }
                />
              </div>
              <div className="app-heading-primary-sm text-truncate">
                {state.currentChatParticipants[0]?.name}
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: 2 }}>
              <button
                className="btn btn-primary"
                style={{ marginLeft: "auto" }}
                onClick={handleRefresh}
              >
                <img src="/images/refresh.png" width={20} />
              </button>
            </div>
          </div>
        </Box>
        <Box
          className={styles.messageBoxBody}
          ref={messageBody}
          onScroll={handleScroll}
          sx={{
            height: {
              xs: `calc(100vh - 255px)`,
              md: "calc(100vh - 330px)",
            },
          }}
        >
          {state.messageLoading &&
            (state.messageGetAction == null ||
              state.messageGetAction == "prev") ? (
            <span className={styles.loader}></span>
          ) : (
            <span></span>
          )}
          {renderMessages()}
        </Box>

        <div className={styles.footer}>
          {selectedFileName != "" ? (
            <div className={styles.fileNameContainer}>
              <div>{selectedFileName}</div>
              <div onClick={handleFileClose} className={styles.fileNameClose}>
                x
              </div>
            </div>
          ) : (
            ""
          )}
          <Box
            className={styles.sendInputContainer}
            sx={{
              borderColor: "#d2d2d2 !important",
            }}
          >
            <form className={styles.sendInputForm} onSubmit={formSubmit}>
              <input
                className={styles.sendInput}
                value={messageInputValue}
                onChange={(e) => setMessageInputValue(e.target.value)}
                placeholder="Type your message"
              />

              <input
                id="message-file-input"
                style={{ display: "none" }}
                type="file"
                ref={fileInput}
                onChange={(event) => {
                  setSelectedFileName(event.target.files[0]["name"]);
                }}
              />

              <label
                htmlFor="message-file-input"
                className={styles.sendInputFormFileLabel}
              >
                {attachmenSvg}
              </label>

              <LoadingButton
                variant="contained"
                type="submit"
                sx={{ textTransform: "unset", marginLeft: '10px' }}
                loading={state.sendingMessage}
              >
                Send
              </LoadingButton>
            </form>
          </Box>
        </div>
      </Box>
    </Box>
  );
}

export default MessageBox;
