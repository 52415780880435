import React from 'react'
import DrInboxContextProvider from './contexts/DrInboxContextProvider'
import DirectScoutingInbox from './DirectScoutingInbox'

export default function DrInbox() {
    return (
        <>
            <DrInboxContextProvider>
                <DirectScoutingInbox />
            </DrInboxContextProvider>
        </>
    )
}
