import { makeStyles } from "@material-ui/core";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Stack,
} from "@mui/material";
import React, { useContext } from "react";
import { CandidateContext } from "../../../context/CandidateContext";
import parse from "react-html-parser";
import TemplateSkeleton from "./TemplateSkeleton";

function CVTemplate({ baseColor, candidateData }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding: theme.spacing({ xs: 0, md: 4 }),
    },
    paper: {
      padding: theme.spacing(6),
      color: theme.palette.text.primary,
      backgroundColor: "#ffffff",
      marginBottom: theme.spacing(5),
    },
    header: {
      marginBottom: theme.spacing(3),
    },
    headerTitle: {
      fontWeight: 700,
      fontFamily: "Epilogue",
    },
    headerText: {
      fontSize: "18px",
      fontFamily: "Epilogue",
    },
    section: {
      marginBottom: theme.spacing(3),
    },
    sectionTitle: {
      marginBottom: theme.spacing(1),
      fontWeight: "bold",
      fontFamily: "Poppins",
      color: baseColor,
    },
    experienceTitle: {
      fontWeight: 500,
      fontFamily: "Poppins",
    },
    experienceDescription: {
      margin: theme.spacing(2),
      fontWeight: 400,
      fontFamily: "Poppins",
    },
    listPrimaryText: {
      fontFamily: "Poppins",
      color: theme.palette.text.primary,
    },
    listSecondaryText: {
      fontFamily: "Poppins",
      color: theme.palette.text.secondary,
    },
    divider: {
      margin: theme.spacing(2, 0),
    },
    jobLocationColor: {
      color: "#a39b9b",
    },
  }));

  const classes = useStyles();

  //   --- data conversion for user better readability ---
  const convertDateFormat = (dateStr) => {
    const date = new Date(dateStr);
    const options = { year: "numeric", month: "long" };
    return date.toLocaleDateString("en-US", options);
  };

  const calculateDuration = (startDateStr, endDateStr) => {
    const startDate = new Date(startDateStr);
    const endDate = endDateStr ? new Date(endDateStr) : new Date();

    const totalMonths =
      (endDate.getFullYear() - startDate.getFullYear()) * 12 +
      (endDate.getMonth() - startDate.getMonth());

    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;

    let duration = "";
    if (years > 0) duration += `${years} year${years > 1 ? "s" : ""} `;
    if (months > 0) duration += `${months} month${months > 1 ? "s" : ""}`;

    return duration.trim();
  };

  return (
    <Box className={classes.root} sx={{ width: "100%", height: "auto" }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={10}>
          <Paper className={classes.paper}>
            <Box className={classes.header}>
              {/* Candidate Name */}
              <Typography
                className={classes.headerTitle}
                variant="h5"
                gutterBottom
              >
                {candidateData?.first_name}
              </Typography>

              {/* Candidate Profession */}
              <Typography
                className={classes.headerText}
                variant="body1"
                gutterBottom
              >
                {candidateData?.profession}
              </Typography>
            </Box>

            <Divider className={classes.divider} />

            <Box className={classes.section}>
              {/* Candidate Contact Info */}
              <Typography
                variant="body2"
                gutterBottom
                sx={{ fontFamily: "Poppins, sans-serif" }}
              >
                Number: +{candidateData?.dial_code} {candidateData?.phone_no} ·
                Email: {candidateData?.user?.email}
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ fontFamily: "Poppins, sans-serif" }}
              >
                Address: {candidateData?.present_address}
              </Typography>
            </Box>

            <Divider className={classes.divider} />

            <Box className={classes.section}>
              <Typography variant="h6" className={classes.sectionTitle}>
                Profile Summary
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontFamily: "Poppins, sans-serif", fontSize: "15px" }}
              >
                {parse(candidateData?.intro)}
              </Typography>
            </Box>

            <Divider className={classes.divider} />

            <Box className={classes.section}>
              <Typography variant="h6" className={classes.sectionTitle}>
                Key Competencies
              </Typography>
              <Grid container spacing={0}>
                {candidateData?.skills.map((skill, index) => {
                  return (
                    <Grid key={index} item xs={12} sm={4} p={0}>
                      <Typography variant="subtitle1">
                        {skill.skill_name}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>

            <Divider className={classes.divider} />

            <Box className={classes.section}>
              <Typography variant="h6" className={classes.sectionTitle}>
                Professional Experience
              </Typography>

              {candidateData?.candidate_experience.map((experience, index) => {
                return (
                  <React.Fragment key={index}>
                    <Box>
                      <Stack spacing={0}>
                        <Typography
                          variant="subtitle1"
                          className={classes.experienceTitle}
                        >
                          {experience.organization_name}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className={classes.experienceTitle}
                        >
                          {experience?.role}
                        </Typography>
                      </Stack>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontFamily: "Poppins, sans-serif" }}
                      >
                        {convertDateFormat(experience.start_date)} -{" "}
                        {experience.currently_working == 1
                          ? `Present (${calculateDuration(
                              experience.start_date,
                              null
                            )})`
                          : `${convertDateFormat(
                              experience.end_date
                            )} (${calculateDuration(
                              experience.start_date,
                              experience.end_date
                            )})`}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        className={classes.jobLocationColor}
                      >
                        {experience?.company_location
                          ? experience?.company_location
                          : ""}
                      </Typography>

                      <Typography
                        variant="body1"
                        className={classes.experienceDescription}
                        paragraph
                      >
                        {parse(experience.organization_desc)}
                      </Typography>
                    </Box>
                  </React.Fragment>
                );
              })}
            </Box>

            <Divider className={classes.divider} />

            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Box className={classes.section}>
                  <Typography variant="h6" className={classes.sectionTitle}>
                    Education
                  </Typography>
                  <List dense>
                    {candidateData?.candidate_education.map(
                      (education, index) => {
                        return (
                          <ListItem key={index}>
                            <ListItemText
                              primary={education.degree_name}
                              secondary={
                                education.major
                                  ? `Majors: ${education.major}, ${education.degree_institute}`
                                  : education.degree_institute
                              }
                              classes={{
                                primary: classes.listPrimaryText,
                                secondary: classes.listSecondaryText,
                              }}
                            />
                          </ListItem>
                        );
                      }
                    )}
                  </List>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box className={classes.section}>
                  <Typography variant="h6" className={classes.sectionTitle}>
                    Certifications
                  </Typography>
                  <List dense>
                    {candidateData?.certification.map((certificate, index) => {
                      return (
                        <ListItem key={index}>
                          <ListItemText
                            primary={certificate.name}
                            secondary={certificate.institute}
                            classes={{
                              primary: classes.listPrimaryText,
                              secondary: classes.listSecondaryText,
                            }}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default function ATSTemplate({ baseColor }) {
  const { candidateData } = useContext(CandidateContext);
  return candidateData?.id ? (
    <CVTemplate baseColor={baseColor} candidateData={candidateData} />
  ) : (
    <TemplateSkeleton />
  );
}
