import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import { BriefcaseIcon } from "../../IconsSVG";
import GlobalLoader from "../../GlobalLoader";
import ReactHtmlParser from 'react-html-parser'
import { Link } from 'react-router-dom';


function JobList({ item, onTabChange }) {
    return (
        <Box
            sx={{
                border: '1px solid #0275D8',
                // display: 'flex',
                // justifyContent: 'space-between',
                padding: 2,
                borderRadius: '6px',
                // alignItems: 'center'
            }}
        >
            <Stack sx={{ width: "100%" }} spacing={2} direction={{ xs: 'column', sm: 'row' }} justifyContent={{ xs: "flex-start", sm: "space-between" }} alignItems={{ xs: "flex-start", sm: "center" }}>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 2, justifyContent: "flex-start", alignItems: "center" }}>
                    <Box sx={{ whiteSpace: "nowrap", display: "flex", alignItems: "center" }}>
                        <BriefcaseIcon />
                    </Box>
                    <Typography
                        variant="body1"
                        sx={{
                            fontFamily: "Poppins",
                            fontWeight: 600,
                            fontSize: "14.9px",
                            lineHeight: "22.35px",
                            color: '#0079C1'
                        }}
                    >
                        {item?.job_title}
                    </Typography>
                </Box>

                <Link to={`/jobs/${item?.post_id}${item?.slug ? `/${item?.slug}` : ''}`}>
                    <Button
                        variant="outlined"
                        sx={{
                            textTransform: 'capitalize',
                            whiteSpace: "nowrap",
                            backgroundColor: "#E5F5FF",
                            color: "#0079C1",
                            minWidth: "100px",
                            marginLeft: { xs: "55px", sm: "0px" }
                        }}
                        onClick={() => onTabChange(null, 'job_details')}
                    >
                        View Details
                    </Button>
                </Link>

            </Stack>
        </Box>
    )
}

function SingleJobOpenings(props) {
    const [content, setContent] = useState([]);
    const [imageContent, setImageContent] = useState([]);

    useEffect(() => {
        if (props?.companyDetails?.employer_contents) {
            let formattedContent = [];
            /* eslint-disable no-unused-expressions */
            props?.companyDetails?.employer_contents?.forEach((item) => {
                formattedContent[item.employer_content_section_id - 1] = item.description;
            });

            let formattedImageContent = [];
            props?.companyDetails?.employer_contents?.forEach((item) => {
                formattedImageContent[item.employer_content_section_id - 1] = item.s3_url;
            });


            // Set content with all descriptions
            setContent((prevContent) => [...prevContent, ...formattedContent]);
            setImageContent((prevImageContent) => [...prevImageContent, ...formattedImageContent]);
        }
    }, [props.companyDetails]);

    const handleCopy = (e) => {
        e.preventDefault();
        alert("Copying is disabled!")
    }

    return (
        props.loading ?
            <GlobalLoader height="70vh" />
            :
            <Box sx={{ px: { xs: 0, md: 8, minHeight: '100vh' } }}>
                <Paper
                    id='join_us'
                    sx={{
                        borderRadius: '5px',
                    }}>
                    <Stack padding={4}>
                        <Typography
                            variant="h5"
                            onCopy={handleCopy}
                            onCut={handleCopy}
                            sx={{
                                fontFamily: "Epilogue",
                                fontWeight: 700,
                                fontSize: { xs: "13.24px", md: "20px" },
                                lineHeight: "16.55px",
                            }}>
                            Join with us
                        </Typography>
                    </Stack>

                    <Divider />

                    <Stack spacing={2} onCopy={handleCopy} onCut={handleCopy} padding={4}>
                        <Typography
                            variant="body1"
                            sx={{
                                fontFamily: "Poppins",
                                fontWeight: 400,
                                fontSize: "14.7px",
                                lineHeight: "22.05px",
                                color: "#605C78",
                                whiteSpace: "pre-line",
                            }}
                        >
                            {ReactHtmlParser(content[4])}
                        </Typography>

                        <Typography
                            variant="body1"
                            sx={{
                                my: 2,
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                fontSize: '14.9px',
                                lineHeight: "22.35px",
                                color: '#0079C1',
                            }}
                        >
                            We are currently on the look out for the following positions:
                        </Typography>

                        {/* opening Job list */}
                        <Grid container rowSpacing={2} columnSpacing={2}>
                            {props?.openingsJob?.map((job, index) => {
                                return (
                                    <Grid item xs={12} lg={6} key={index} sx={{ marginLeft: { xs: "-16px", lg: "0px" } }}> <JobList item={job} onTabChange={props.onTabChange} /> </Grid>
                                )
                            })}
                        </Grid>

                        {/* Images */}
                        {imageContent[4] ?
                            <img src={imageContent[4] ? imageContent[4] : '/v2/images/banner-placeholder.png'} width="100%" height="100%" alt="Join With Us Image" />
                            :
                            null
                        }

                        {/* all jobs button */}
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "24px" }}>
                            <Link
                                to={{
                                    pathname: `/search/jobs`,
                                    state: { fromHome: true },
                                    search: `?employer_name=${props?.companyDetails?.employer_name}`,
                                }}
                            >
                                <Button
                                    variant="contained"
                                    sx={{
                                        maxWidth: "170px",
                                        fontFamily: "Epilogue",
                                        fontWeight: 700,
                                        fontSize: "11.56px",
                                        lineHeight: "18.54px",
                                        backgroundColor: "#0079C1",
                                        color: "white",
                                        textTransform: 'capitalize',
                                        py: 1.5,
                                    }}
                                >
                                    View All Job Openings
                                </Button>
                            </Link>
                        </Box>
                    </Stack>

                </Paper>
            </Box>
    )
}

export default SingleJobOpenings