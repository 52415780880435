import React, { useState } from 'react'
import CandidateDashboardSidebar from '../../components/CandidateComponents/CandidateDashboardSideBar/CandidateDashboardSidebar'
import CandidateMessage from '../../components/CandidateComponents/CandidateMessage'
import { Box } from '@material-ui/core'
import { Grid } from '@mui/material'
import ShortFooter from '../../components/ShortFooter'
import { Card as BCard } from "react-bootstrap"
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import DrInbox from './dr/inbox/DrInbox'


export default function CandidateMessagePage() {
    const [tabValue, setTabValue] = useState('general_message');

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <div className='' >
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, position: 'fixed', height: '70px', backgroundColor: '#0275D8', width: '100%', zIndex: 10 }}></Box>

            <Grid container justifyContent="flex-end" sx={{ position: "relative", minHeight: { xs: "100vh", md: "100%" } }}>
                <Grid item xs={0} sm={0} md={3} lg={2.5} xl={2} sx={{ zIndex: 11, display: { xs: 'none', sm: 'none', md: 'block' } }}>
                    <CandidateDashboardSidebar />
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9.5} xl={10} sx={{ zIndex: 11, display: { md: 'block', marginTop: "30px" }, padding: { xs: '0px 0px 100px 0px' } }}>

                    {/* -- Tab content start -- */}
                    <BCard className="shadow-sm" style={{ margin: '20px' }}>
                        <Box component='div' sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={tabValue}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList sx={{ paddingTop: '41px', paddingLeft: '23px', borderBottom: '1px solid #C2C2C2' }} onChange={handleTabChange} aria-label="lab API tabs example">
                                        <Tab sx={{
                                            color: tabValue === "general_message" ? "#fff !important" : "#72737C",
                                            backgroundColor: tabValue === "general_message" ? "#0079C1" : "#fff",
                                            marginRight: '11px',
                                            borderTopLeftRadius: tabValue === "general_message" ? "6px" : "0px",
                                            borderTopRightRadius: tabValue === "general_message" ? "6px" : "0px",
                                            outline: "none",
                                            "&:focus": {
                                                outline: "none",
                                            },
                                            textTransform: 'capitalize',
                                            fontSize: { xs: '14px', lg: '16px' },
                                            fontFamily: 'Poppins',
                                            fontWeight: '600',
                                            lineHeight: '24px'
                                        }} label="General Message" value="general_message" />

                                        <Tab sx={{
                                            color: tabValue === "direct_scout_message" ? "#fff !important" : "#72737C",
                                            backgroundColor: tabValue === "direct_scout_message" ? "#0079C1" : "#fff",
                                            borderTopLeftRadius: tabValue === "direct_scout_message" ? "6px" : "0px",
                                            borderTopRightRadius: tabValue === "direct_scout_message" ? "6px" : "0px",
                                            outline: "none",
                                            "&:focus": {
                                                outline: "none",
                                            },
                                            textTransform: 'capitalize',
                                            fontSize: { xs: '14px', lg: '16px' },
                                            fontFamily: 'Poppins',
                                            fontWeight: '600',
                                            lineHeight: '24px'
                                        }} label="Direct scout message" value="direct_scout_message" />

                                    </TabList>
                                </Box>
                                <TabPanel value="general_message" sx={{ padding: '0px' }}>
                                    <CandidateMessage />
                                </TabPanel>
                                <TabPanel value="direct_scout_message" sx={{ padding: '0px' }}>
                                    <DrInbox />
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </BCard>
                    {/* -- Tab content end -- */}

                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9.5} xl={10} sx={{ position: "absolute", bottom: 0, width: "100%" }}>
                    <ShortFooter />
                </Grid>
            </Grid>
        </div>
    )
}