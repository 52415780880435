import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkIcon from "@mui/icons-material/Link";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import GlobalLoader from "../../GlobalLoader";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import { useHistory } from "react-router-dom";
import CandidateContextProvider, {
  CandidateContext,
} from "../../../context/CandidateContext";
import { UserContext } from "../../../context/UserContext";
import {
  deleteCandidateSaveJobInfo,
  followCompany,
  postActivityLikes,
  postCandidateSaveJobInfo,
  unFollowCompany,
} from "../../../../fetchAPI";
import { fetchIpAddress } from "../../../helpers/helperFunctions";
import { LoadingButton } from "@mui/lab";
import ApplyToJobModal from "../ApplyScreen/ApplyToJobModal";
import OpenAppComponent from "../../OpenAppComponent";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Fade from "@mui/material/Fade";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import EmployerProfilePicture from "../../EmployerComponents/EmployerProfilePicture";
import SuccessAlert from "../../Alerts/SuccessAlert";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip
    disableFocusListener
    disableHoverListener
    disableTouchListener
    TransitionComponent={Fade}
    TransitionProps={{ timeout: 600 }}
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#00A264",
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

function DotIcon() {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 16.00 16.00"
      xmlns="http://www.w3.org/2000/svg"
      fill="#605C78"
      class="bi bi-dot"
      stroke="#605C78"
      stroke-width="0.00016"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"></path>{" "}
      </g>
    </svg>
  );
}

function ThumbsUpIcon({ color = "#1976D2", size = "24px" }) {
  return (
    <svg
      fill={color}
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path d="M323.8 34.8c-38.2-10.9-78.1 11.2-89 49.4l-5.7 20c-3.7 13-10.4 25-19.5 35l-51.3 56.4c-8.9 9.8-8.2 25 1.6 33.9s25 8.2 33.9-1.6l51.3-56.4c14.1-15.5 24.4-34 30.1-54.1l5.7-20c3.6-12.7 16.9-20.1 29.7-16.5s20.1 16.9 16.5 29.7l-5.7 20c-5.7 19.9-14.7 38.7-26.6 55.5c-5.2 7.3-5.8 16.9-1.7 24.9s12.3 13 21.3 13L448 224c8.8 0 16 7.2 16 16c0 6.8-4.3 12.7-10.4 15c-7.4 2.8-13 9-14.9 16.7s.1 15.8 5.3 21.7c2.5 2.8 4 6.5 4 10.6c0 7.8-5.6 14.3-13 15.7c-8.2 1.6-15.1 7.3-18 15.2s-1.6 16.7 3.6 23.3c2.1 2.7 3.4 6.1 3.4 9.9c0 6.7-4.2 12.6-10.2 14.9c-11.5 4.5-17.7 16.9-14.4 28.8c.4 1.3 .6 2.8 .6 4.3c0 8.8-7.2 16-16 16H286.5c-12.6 0-25-3.7-35.5-10.7l-61.7-41.1c-11-7.4-25.9-4.4-33.3 6.7s-4.4 25.9 6.7 33.3l61.7 41.1c18.4 12.3 40 18.8 62.1 18.8H384c34.7 0 62.9-27.6 64-62c14.6-11.7 24-29.7 24-50c0-4.5-.5-8.8-1.3-13c15.4-11.7 25.3-30.2 25.3-51c0-6.5-1-12.8-2.8-18.7C504.8 273.7 512 257.7 512 240c0-35.3-28.6-64-64-64l-92.3 0c4.7-10.4 8.7-21.2 11.8-32.2l5.7-20c10.9-38.2-11.2-78.1-49.4-89zM32 192c-17.7 0-32 14.3-32 32V448c0 17.7 14.3 32 32 32H96c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32H32z" />
    </svg>
  );
}

function ThumbsUpFillIcon({ color = "#1976D2", size = "24px" }) {
  return (
    <svg
      fill={color}
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2H464c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48H294.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V320 272 247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192H96c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32z" />
    </svg>
  );
}

function InformationAboutJob(props) {
  const handleCopy = (e) => {
    e.preventDefault();
    alert("Copying is disabled!");
  };
  return (
    <Box onCopy={handleCopy} onCut={handleCopy} sx={{ mt: 4 }}>
      <Typography
        variant="h6"
        align="left"
        sx={{
          color: "#00457C",
          fontFamily: "Poppins",
          fontWeight: 700,
          fontSize: "16.8px",
          lineHeight: "25.2px",
          marginBottom: 2,
          "@media (max-width: 600px)": {
            fontSize: "16px",
          },
        }}
      >
        {props.title}
      </Typography>
      <Typography
        variant="body1"
        align="left"
        component="div" // Renders as a div instead of a <p>
        sx={{
          color: "#605C78",
          fontFamily: "Poppins",
          fontWeight: 400,
          fontSize: "14.7px",
          lineHeight: "22.05px",
          marginBottom: 2,
          "@media (max-width: 600px)": {
            fontSize: "16px",
          },
          ul: {
            listStyleType: "disc",
            paddingLeft: "20px", // Proper indentation for lists
            margin: 0,
          },
          ol: {
            listStyleType: "decimal",
            paddingLeft: "20px",
            margin: 0,
          },
          li: {
            marginBottom: "8px", // Add spacing between items
          },
        }}
      >
        {props.content}
      </Typography>

      {props.subContent && (
        <Typography
          variant="body1"
          align="left"
          sx={{
            color: "#605C78",
            fontFamily: "Poppins",
            fontWeight: 400,
            fontSize: "14.7px",
            lineHeight: "22.05px",
            marginBottom: 2,
            "@media (max-width: 600px)": {
              fontSize: "16px",
            },
          }}
        >
          {props.subContent}
        </Typography>
      )}
    </Box>
  );
}

function JobInformationCard({ jobPost }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [openApply, setOpenApply] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [likeLoading, setLikeLoading] = useState(false);
  const [likedJob, setLikedJob] = useState(false);
  const [likeCount, setlikeCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const pageTitle = "job-details";
  const currentPageURL = window.location.href;
  const userAgent = navigator.userAgent;

  const {
    appliedJobsIds,
    savedJobIds,
    candidateData,
    settingCandidateData,
    candLoader,
  } = useContext(CandidateContext);
  const { userData, isLoggedin } = useContext(UserContext);
  const isApplied = useMemo(() => appliedJobsIds.includes(jobPost?.post_id), [
    appliedJobsIds,
    jobPost?.post_id,
  ]);

  const history = useHistory();

  useEffect(() => {
    if (jobPost?.is_user_like) {
      setLikedJob(true);
    }
    setlikeCount(jobPost?.like_count);
  }, [jobPost?.is_user_like, jobPost?.like_count]);

  const handleJobApplyClick = () => {
    if (!isApplied) {
      setOpenApply(true);
    }
  };

  const unSaveCandidate = (candId, jobId) => {
    if (isLoggedin) {
      setSaveLoading(false);
      setLoading(true);

      deleteCandidateSaveJobInfo(candId, jobId)
        .then((response) => {
          if (response && response.success) {
            settingCandidateData();
            if (!savedJobIds.includes(jobPost?.post_id) && !candLoader) {
              setLoading(false);
            }
          } else {
            setLoading(false);
            // Handle failure, show error message or take appropriate action
            console.log(response);
          }
        })
        .catch((error) => {
          // Handle errors, show error message or take appropriate action
          console.log(error);
        })
        .finally(() => {
          // setLoading(false);
        });
    } else {
      history.push("/login");
    }
  };

  const saveCandidate = (candId, jobId) => {
    if (isLoggedin) {
      setLoading(false);
      setSaveLoading(true);

      postCandidateSaveJobInfo(candId, jobId)
        .then((response) => {
          if (response && response.success) {
            settingCandidateData();
          } else {
            // Handle failure, show error message or take appropriate action
            if (
              !userData &&
              response.errors[0].message === "Unauthenticated."
            ) {
              history.push("/login");
            } else {
              console.log(response);
            }
          }
        })
        .catch((error) => {
          // Handle errors, show error message or take appropriate action
          console.log(error);
        })
        .finally(() => {
          // setLoading(false);
        });
    } else {
      history.push("/login");
    }
  };

  const likeJobPost = async (id) => {
    if (isLoggedin) {
      if (!likedJob) {
        setLikeLoading(true);
        const ipAddress = await fetchIpAddress();
        const params = {
          page_title: pageTitle,
          action_type: "view",
          page_url: currentPageURL,
          ip: ipAddress,
          job_post_id: id,
          platform: "web",
          user_agent: userAgent,
        };
        await postActivityLikes(params).then((res) => {
          if (res && res.success) {
            setLikeLoading(false);
            setLikedJob(true);
            setlikeCount(jobPost?.like_count + 1);
          } else {
            console.log(res);
          }
        });
      }
    } else {
      history.push("/login");
    }
  };


  // -- show hide toggle button --
  const contentRef = useRef(null);

  const toggleExpansion = () => {
    if (isExpanded && contentRef.current) {
      const contentTop = contentRef.current.getBoundingClientRect().top + window.scrollY;

      window.scrollTo({ top: contentTop });

      requestAnimationFrame(() => {
        setIsExpanded(false);
      });
    } else {
      setIsExpanded(true);
    }
  };

  const dayLeft = (date) => {
    const today = moment(new Date(), "YYYY-MM-DD").startOf("day");
    const targetDate = moment(date, "YYYY-MM-DD").startOf("day");

    let days = targetDate.diff(today, "days");
    if (days >= 0) {
      return days;
    } else {
      return null;
    }
  };
  return (
    <>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "10px",
          boxShadow: 3,
        }}
      >
        {/* job title */}
        <Box
          sx={{
            px: 4,
            py: 5,
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            justifyContent: "space-between",
            alignItems: { xs: "start", lg: "center" },
          }}
        >
          <Box sx={{ width: { xs: "100%", lg: "calc(100% - 230px)" } }}>
            <Typography
              variant="h4"
              sx={{
                fontFamily: "Poppins",
                fontWeight: 700,
                color: "#0079C1",
                fontSize: "24px",
                "@media (max-width: 600px)": {
                  fontSize: "18px",
                },
              }}
            >
              {jobPost?.job_title}
            </Typography>

            {jobPost?.is_cxo ? (
              <Typography
                variant="h6"
                sx={{
                  fontSize: "18px",
                  fontWeight: 700,
                  filter: jobPost?.is_cxo === 0 ? "none" : "blur(5px)",
                  userSelect: jobPost?.is_cxo === 0 ? "auto" : "none",
                }}
              >
                {jobPost?.employer?.employer_name} {jobPost?.number_of_vacancy && jobPost?.number_of_vacancy !== null && <Typography component='span'>{jobPost?.number_of_vacancy}</Typography>}
              </Typography>
            ) : (
              <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                <Typography
                  variant="h4"
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    color: "#605C78",
                    fontSize: "14px",
                    "@media (max-width: 600px)": {
                      fontSize: "12px",
                    },
                  }}
                >
                  {jobPost?.employer?.employer_name}
                </Typography>
                {
                  jobPost?.number_of_vacancy && jobPost?.number_of_vacancy !== null && <Typography
                    variant="h4"
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      color: "#605C78",
                      fontSize: "14px",
                      "@media (max-width: 600px)": {
                        fontSize: "12px",
                      },
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <DotIcon />
                    Vacancy: {jobPost?.number_of_vacancy}
                  </Typography>
                }

              </Box>
            )}
          </Box>

          {/* apply job save job like job feature */}
          <Stack
            spacing={1.5}
            direction="row"
            minWidth="230px"
            sx={{
              justifyContent: { xs: "flex-start", lg: "flex-end" },
              marginTop: { xs: 1, lg: 0 },
            }}
          >
            <Box>
              <LoadingButton
                className="ga_apply_button"
                loading={candLoader}
                size="large"
                variant="contained"
                onClick={handleJobApplyClick}
                sx={{
                  color: `${isApplied ? "#15A449" : "#ffffff"}`,
                  fontSize: "14px",
                  fontFamily: "Epilogue,sans-serif",
                  fontWeight: "700",
                  backgroundColor: `${isApplied ? "#E5F5FF" : "#00A264"}`,
                  textTransform: "capitalize",
                  cursor: `${isApplied && "default"}`,
                  ":focus": { outline: "none" },
                  "&:hover": {
                    backgroundColor: `${isApplied ? "#E5F5FF" : "#00A264"}`,
                  },
                }}
              >
                {isApplied ? "Applied" : "Apply Now"}
              </LoadingButton>
            </Box>

            {/* Save Button */}
            {savedJobIds.includes(jobPost?.post_id) ? (
              <Tooltip title="Saved" placement="top" arrow>
                <LoadingButton
                  loading={loading}
                  variant="outlined"
                  size="small"
                  onClick={() =>
                    unSaveCandidate(candidateData.id, jobPost?.post_id)
                  }
                  loadingPosition="center"
                  sx={{
                    flexWrap: "nowrap",
                    fontSize: "0.9375rem",
                    boxShadow: 0,
                    lineHeight: "0px",
                    height: "40px",
                    ":focus": { outline: "none" },
                    padding: "0px !important",
                    minWidth: "40px",
                  }}
                >
                  {!loading && <FavoriteIcon style={{ color: "red" }} />}
                </LoadingButton>
              </Tooltip>
            ) : (
              <Tooltip title="Save Job" placement="top" arrow>
                <LoadingButton
                  loading={saveLoading}
                  variant="outlined"
                  size="small"
                  onClick={() =>
                    saveCandidate(candidateData.id, {
                      job_post_id: jobPost?.post_id,
                    })
                  }
                  sx={{
                    flexWrap: "nowrap",
                    fontSize: "0.9375rem",
                    boxShadow: 0,
                    lineHeight: "0px",
                    height: "40px",
                    ":focus": { outline: "none" },
                    padding: "0px !important",
                    minWidth: "40px",
                  }}
                >
                  <FavoriteBorderIcon />
                </LoadingButton>
              </Tooltip>
            )}

            {/* Like button */}
            <Tooltip
              title={`${likedJob ? "Liked" : "Like Job"} - ${likeCount}`}
              placement="top"
              arrow
            >
              <LoadingButton
                loading={likeLoading}
                variant="outlined"
                size="small"
                onClick={() => likeJobPost(jobPost.post_id)}
                sx={{
                  flexWrap: "nowrap",
                  boxShadow: 0,
                  lineHeight: "0px",
                  height: "40px",
                  cursor: "pointer",
                  ":focus": { outline: "none" },
                  padding: "0px !important",
                  minWidth: "40px",
                }}
              >
                {!likeLoading ? (
                  likedJob ? (
                    <ThumbsUpFillIcon />
                  ) : (
                    <ThumbsUpIcon />
                  )
                ) : null}
              </LoadingButton>
            </Tooltip>
          </Stack>
        </Box>

        {/* jobs information small screen */}
        <Box
          sx={{
            gap: 6,
            p: 4,
            backgroundColor: "#F9F9F9",
            display: { xs: "block", lg: "none" },
          }}
        >
          <Box sx={{ display: "flex", columnGap: 4 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 1.5,
                mb: 2,
                minWidth: "140px",
              }}
            >
              <Typography
                sx={{
                  color: "#72737C",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "19.5px",
                }}
              >
                Monthly Salary
              </Typography>
              <Typography
                sx={{
                  color: "#424447",
                  fontFamily: "Epilogue",
                  fontWeight: 600,
                  fontSize: "15px",
                  lineHeight: "15.37px",
                }}
              >
                {
                  jobPost?.negotiable
                    ? "Negotiable"
                    : `${jobPost?.minimum_salary || ""} ${jobPost?.maximum_salary && jobPost?.maximum_salary !== null ? `- ${jobPost?.maximum_salary} /` : ""} ${jobPost?.salary_currency?.name || "Taka"}`
                }
              </Typography>
            </Box>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 1.5,
                mb: 2,
              }}
            >
              <Typography
                sx={{
                  color: "#72737C",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "19.5px",
                }}
              >
                Experience
              </Typography>
              <Typography
                sx={{
                  color: "#424447",
                  fontFamily: "Epilogue",
                  fontWeight: 500,
                  fontSize: "15px",
                  lineHeight: "15.37px",
                }}
              >
                {
                  `${jobPost?.minimum_experience && jobPost?.minimum_experience !== null ? `${jobPost?.minimum_experience} - ` : ""}${jobPost?.maximum_experience && jobPost?.maximum_experience !== null ? `${jobPost?.maximum_experience}` : ""} Years`
                }
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex", columnGap: 4 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 1.5,
                mb: 2,
                minWidth: "140px",
              }}
            >
              <Typography
                sx={{
                  color: "#72737C",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "19.5px",
                }}
              >
                Job Type
              </Typography>
              <Typography
                sx={{
                  color: "#424447",
                  fontFamily: "Epilogue",
                  fontWeight: 500,
                  fontSize: "15px",
                  lineHeight: "15.37px",
                }}
              >
                {jobPost?.job_type?.name}
              </Typography>
            </Box>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 1.5,
                mb: 2,
              }}
            >
              <Typography
                sx={{
                  color: "#72737C",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "19.5px",
                }}
              >
                Job Location
              </Typography>
              {jobPost?.is_cxo ? (
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "18px",
                    fontWeight: 700,
                    filter: jobPost?.is_cxo === 0 ? "none" : "blur(5px)",
                    userSelect: jobPost?.is_cxo === 0 ? "auto" : "none",
                  }}
                >
                  {jobPost?.job_address}
                </Typography>
              ) : (
                <Typography
                  sx={{
                    color: "#424447",
                    fontFamily: "Epilogue",
                    fontWeight: 500,
                    fontSize: "15px",
                    lineHeight: "15.37px",
                  }}
                >
                  {jobPost?.job_address}
                </Typography>
              )}
            </Box>
          </Box>

          <Box sx={{ display: "flex", columnGap: 4 }}>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ display: { xs: "none" } }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 1.5,
                mb: 2,
              }}
            >
              <Typography
                sx={{
                  color: "#72737C",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "19.5px",
                }}
              >
                Deadline
              </Typography>
              <Typography
                sx={{
                  color: "#424447",
                  fontFamily: "Epilogue",
                  fontWeight: 500,
                  fontSize: "15px",
                  lineHeight: "15.37px",
                }}
              >
                <span>
                  {moment(jobPost?.job_deadline).format("MMM Do, YY")}
                </span>
                &nbsp;
                <span style={{ color: "#DB1616" }}>
                  {(() => {
                    const daysLeft = dayLeft(jobPost?.job_deadline);
                    return daysLeft === 0
                      ? `(Expiring Today)`
                      : daysLeft === 1
                        ? `(${daysLeft} day left)`
                        : daysLeft > 1 && daysLeft < 8
                          ? `(${daysLeft} days left)`
                          : ``;
                  })()}
                </span>
              </Typography>
            </Box>
          </Box>

          {/* skills */}
          {jobPost?.skills.length > 0 && (
            <Box sx={{ mt: 1 }}>
              <Typography
                sx={{
                  color: "#00457C",
                  fontFamily: "Poppins",
                  fontWeight: 700,
                  fontSize: "16.8px",
                  lineHeight: "25.2px",
                }}
              >
                Skills Required
              </Typography>
              <Stack direction="row" flexWrap="wrap" gap={1} mt={2}>
                {jobPost?.skills.map((item) => {
                  return (
                    <Chip
                      key={item.skill_id}
                      label={item?.skill?.skill_name}
                      sx={{ backgroundColor: "#E5F5FF", color: "#72737C" }}
                    />
                  );
                })}
              </Stack>
            </Box>
          )}
        </Box>

        {/* jobs information large screen */}
        <Box
          sx={{
            gap: 6,
            p: 4,
            backgroundColor: "#F9F9F9",
            display: { xs: "none", lg: "block", xl: "none" },
          }}
        >
          <Box sx={{ display: "flex", columnGap: 4 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 1.5,
                mb: 2,
                minWidth: "140px",
              }}
            >
              <Typography
                sx={{
                  color: "#72737C",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "19.5px",
                }}
              >
                Monthly Salary
              </Typography>
              <Typography
                sx={{
                  color: "#424447",
                  fontFamily: "Epilogue",
                  fontWeight: 600,
                  fontSize: "15px",
                  lineHeight: "15.37px",
                }}
              >
                {
                  jobPost?.negotiable
                    ? "Negotiable"
                    : `${jobPost?.minimum_salary || ""} ${jobPost?.maximum_salary && jobPost?.maximum_salary !== null ? `- ${jobPost?.maximum_salary} /` : ""} ${jobPost?.salary_currency?.name || "Taka"}`
                }
              </Typography>
            </Box>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 1.5,
                mb: 2,
                minWidth: "100px",
              }}
            >
              <Typography
                sx={{
                  color: "#72737C",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "19.5px",
                }}
              >
                Experience
              </Typography>
              <Typography
                sx={{
                  color: "#424447",
                  fontFamily: "Epilogue",
                  fontWeight: 500,
                  fontSize: "15px",
                  lineHeight: "15.37px",
                }}
              >
                {
                  `${jobPost?.minimum_experience && jobPost?.minimum_experience !== null ? `${jobPost?.minimum_experience} - ` : ""}${jobPost?.maximum_experience && jobPost?.maximum_experience !== null ? `${jobPost?.maximum_experience}` : ""} Years`
                }
              </Typography>
            </Box>

            <Divider orientation="vertical" variant="middle" flexItem />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 1.5,
                mb: 2,
                minWidth: "100px",
              }}
            >
              <Typography
                sx={{
                  color: "#72737C",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "19.5px",
                }}
              >
                Job Type
              </Typography>
              <Typography
                sx={{
                  color: "#424447",
                  fontFamily: "Epilogue",
                  fontWeight: 500,
                  fontSize: "15px",
                  lineHeight: "15.37px",
                }}
              >
                {jobPost?.job_type?.name}
              </Typography>
            </Box>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 1.5,
                mb: 2,
                minWidth: "140px",
              }}
            >
              <Typography
                sx={{
                  color: "#72737C",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "19.5px",
                }}
              >
                Job Location
              </Typography>
              {jobPost?.is_cxo ? (
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "18px",
                    fontWeight: 700,
                    filter: jobPost?.is_cxo === 0 ? "none" : "blur(5px)",
                    userSelect: jobPost?.is_cxo === 0 ? "auto" : "none",
                  }}
                >
                  {jobPost?.job_address}
                </Typography>
              ) : (
                <Typography
                  sx={{
                    color: "#424447",
                    fontFamily: "Epilogue",
                    fontWeight: 500,
                    fontSize: "15px",
                    lineHeight: "15.37px",
                  }}
                >
                  {jobPost?.job_address}
                </Typography>
              )}
            </Box>
          </Box>

          <Box sx={{ display: "flex", columnGap: 4 }}>
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ display: { xs: "none" } }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 1.5,
                mb: 2,
              }}
            >
              <Typography
                sx={{
                  color: "#72737C",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "19.5px",
                }}
              >
                Deadline
              </Typography>
              <Typography
                sx={{
                  color: "#424447",
                  fontFamily: "Epilogue",
                  fontWeight: 500,
                  fontSize: "15px",
                  lineHeight: "15.37px",
                }}
              >
                <span>
                  {moment(jobPost?.job_deadline).format("MMM Do, YY")}
                </span>
                &nbsp;
                <span style={{ color: "#DB1616" }}>
                  {(() => {
                    const daysLeft = dayLeft(jobPost?.job_deadline);
                    return daysLeft === 0
                      ? `(Expiring Today)`
                      : daysLeft === 1
                        ? `(${daysLeft} day left)`
                        : daysLeft > 1 && daysLeft < 8
                          ? `(${daysLeft} days left)`
                          : ``;
                  })()}
                </span>
              </Typography>
            </Box>
          </Box>

          {/* skills */}
          {jobPost?.skills.length > 0 && (
            <Box sx={{ mt: 1 }}>
              <Typography
                sx={{
                  color: "#00457C",
                  fontFamily: "Poppins",
                  fontWeight: 700,
                  fontSize: "16.8px",
                  lineHeight: "25.2px",
                }}
              >
                Skills Required
              </Typography>
              <Stack direction="row" flexWrap="wrap" gap={1} mt={2}>
                {jobPost?.skills.map((item) => {
                  return (
                    <Chip
                      key={item.skill_id}
                      label={item?.skill?.skill_name}
                      sx={{ backgroundColor: "#E5F5FF", color: "#72737C" }}
                    />
                  );
                })}
              </Stack>
            </Box>
          )}
        </Box>

        {/* jobs information extra large screen */}
        <Box
          sx={{
            gap: 6,
            p: 4,
            backgroundColor: "#F9F9F9",
            display: { xs: "none", lg: "none", xl: "block" },
          }}
        >
          <Box sx={{ display: "flex", columnGap: 4 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 1.5,
                mb: 2,
              }}
            >
              <Typography
                sx={{
                  color: "#72737C",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "19.5px",
                }}
              >
                Monthly Salary
              </Typography>
              <Typography
                sx={{
                  color: "#424447",
                  fontFamily: "Epilogue",
                  fontWeight: 600,
                  fontSize: "15px",
                  lineHeight: "15.37px",
                }}
              >
                {
                  jobPost?.negotiable
                    ? "Negotiable"
                    : `${jobPost?.minimum_salary || ""} ${jobPost?.maximum_salary && jobPost?.maximum_salary !== null ? `- ${jobPost?.maximum_salary} /` : ""} ${jobPost?.salary_currency?.name || "Taka"}`
                }
              </Typography>
            </Box>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 1.5,
                mb: 2,
              }}
            >
              <Typography
                sx={{
                  color: "#72737C",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "19.5px",
                }}
              >
                Experience
              </Typography>
              <Typography
                sx={{
                  color: "#424447",
                  fontFamily: "Epilogue",
                  fontWeight: 500,
                  fontSize: "15px",
                  lineHeight: "15.37px",
                }}
              >
                {
                  `${jobPost?.minimum_experience && jobPost?.minimum_experience !== null ? `${jobPost?.minimum_experience} - ` : ""}${jobPost?.maximum_experience && jobPost?.maximum_experience !== null ? `${jobPost?.maximum_experience}` : ""} Years`
                }
              </Typography>
            </Box>

            <Divider orientation="vertical" variant="middle" flexItem />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 1.5,
                mb: 2,
              }}
            >
              <Typography
                sx={{
                  color: "#72737C",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "19.5px",
                }}
              >
                Job Type
              </Typography>
              <Typography
                sx={{
                  color: "#424447",
                  fontFamily: "Epilogue",
                  fontWeight: 500,
                  fontSize: "15px",
                  lineHeight: "15.37px",
                }}
              >
                {jobPost?.job_type?.name}
              </Typography>
            </Box>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 1.5,
                mb: 2,
              }}
            >
              <Typography
                sx={{
                  color: "#72737C",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "19.5px",
                }}
              >
                Job Location
              </Typography>
              {jobPost?.is_cxo ? (
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "18px",
                    fontWeight: 700,
                    filter: jobPost?.is_cxo === 0 ? "none" : "blur(5px)",
                    userSelect: jobPost?.is_cxo === 0 ? "auto" : "none",
                  }}
                >
                  {jobPost?.job_address}
                </Typography>
              ) : (
                <Typography
                  sx={{
                    color: "#424447",
                    fontFamily: "Epilogue",
                    fontWeight: 500,
                    fontSize: "15px",
                    lineHeight: "15.37px",
                  }}
                >
                  {jobPost?.job_address}
                </Typography>
              )}
            </Box>

            <Divider orientation="vertical" variant="middle" flexItem />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 1.5,
                mb: 2,
              }}
            >
              <Typography
                sx={{
                  color: "#72737C",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "19.5px",
                }}
              >
                Deadline
              </Typography>
              <Typography
                sx={{
                  color: "#424447",
                  fontFamily: "Epilogue",
                  fontWeight: 500,
                  fontSize: "15px",
                  lineHeight: "15.37px",
                }}
              >
                <span>
                  {moment(jobPost?.job_deadline).format("MMM Do, YY")}
                </span>
                &nbsp;
                <span style={{ color: "#DB1616" }}>
                  {(() => {
                    const daysLeft = dayLeft(jobPost?.job_deadline);
                    return daysLeft === 0
                      ? `(Expiring Today)`
                      : daysLeft === 1
                        ? `(${daysLeft} day left)`
                        : daysLeft > 1 && daysLeft < 8
                          ? `(${daysLeft} days left)`
                          : ``;
                  })()}
                </span>
              </Typography>
            </Box>
          </Box>

          {/* skills */}
          {jobPost?.skills.length > 0 && (
            <Box sx={{ mt: 1 }}>
              <Typography
                sx={{
                  color: "#00457C",
                  fontFamily: "Poppins",
                  fontWeight: 700,
                  fontSize: "16.8px",
                  lineHeight: "25.2px",
                }}
              >
                Skills Required
              </Typography>
              <Stack direction="row" flexWrap="wrap" gap={1} mt={2}>
                {jobPost?.skills.map((item) => {
                  return (
                    <Chip
                      key={item.skill_id}
                      label={item?.skill?.skill_name}
                      sx={{ backgroundColor: "#E5F5FF", color: "#72737C" }}
                    />
                  );
                })}
              </Stack>
            </Box>
          )}
        </Box>

        {/* About the Job details */}
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            p: 6,
          }}
        >
          <Box
            ref={contentRef}
            sx={{
              overflow: "hidden",
              maxHeight: { xs: isExpanded ? "none" : "300px", md: "none" },
              transition: "max-height 0.3s ease",
            }}

          >
            {/* <InformationAboutJob title="About the job" content="atB Jobs is seeking a talented and experienced Senior UI/UX Designer to join our growing team. You will play a key role in designing intuitive, user-centric interfaces for web and mobile platforms, driving innovation, and ensuring a seamless user experience for our job portal. Collaborating with cross-functional teams, you will help elevate our platform’s design and usability to new heights." />
                    <InformationAboutJob title="Responsibilities" content="atB Jobs is seeking a talented and experienced Senior UI/UX Designer to join our growing team. You will play a key role in designing intuitive, user-centric interfaces for web and mobile platforms, driving innovation, and ensuring a seamless user experience for our job portal. Collaborating with cross-functional teams, you will help elevate our platform’s design and usability to new heights.atB Jobs is seeking a talented and experienced Senior UI/UX Designer to join our growing team. You will play a key role in designing intuitive, user-centric interfaces for web and mobile platforms, driving innovation, and ensuring a seamless user experience for our job portal. Collaborating with cross-functional teams, you will help elevate our platform’s design and usability to new heights." />
                    <InformationAboutJob title="Qualification and Skill Sets" content="atB Jobs is seeking a talented and experienced Senior UI/UX Designer to join our growing team. You will play a key role in designing intuitive, user-centric interfaces for web and mobile platforms, driving innovation, and ensuring a seamless user experience for our job portal. Collaborating with cross-functional teams, you will help elevate our platform’s design and usability to new heights.atB Jobs is seeking a talented and experienced Senior UI/UX Designer to join our growing team. You will play a key role in designing intuitive, user-centric interfaces for web and mobile platforms, driving innovation, and ensuring a seamless user experience for our job portal. Collaborating with cross-functional teams, you will help elevate our platform’s design and usability to new heights." />
                    <InformationAboutJob title="Salary and Compensations" content="atB Jobs is seeking a talented and experienced Senior UI/UX Designer to join our growing team. You will play a key role in designing intuitive, user-centric interfaces for web and mobile platforms, driving innovation, and ensuring a seamless user experience for our job portal. Collaborating with cross-functional teams, you will help elevate our platform’s design and usability to new heights." />
                */}
            <Box
              sx={{
                height: "100%",
              }}
            >
              {jobPost?.job_description ? (
                <InformationAboutJob
                  title={"Job Description"}
                  content={ReactHtmlParser(jobPost?.job_description)}
                />
              ) : null}

              {jobPost?.qualification?.name ? (
                <InformationAboutJob
                  title={"Education"}
                  content={jobPost?.qualification?.name ? `Minimum Qualification: ${jobPost?.qualification?.name} Degree` : ''}
                  subContent={
                    jobPost?.qualification_prefer?.name
                      ? `Preferred Qualification: ${jobPost?.qualification_prefer?.name} Degree`
                      : ''
                  }
                />
              ) : null}

              {jobPost?.minimum_experience ? (
                <InformationAboutJob
                  title={"Experience"}
                  content={`Atleast ${jobPost?.minimum_experience} years of experience`}
                  subContent={
                    jobPost?.maximum_experience !== null
                      ? `Preferred number of years of experience: ${jobPost?.maximum_experience} years`
                      : null
                  }
                />
              ) : null}

              {jobPost?.job_requirement ? (
                <InformationAboutJob
                  title={"Job Requirements"}
                  content={ReactHtmlParser(jobPost?.job_requirement)}
                />
              ) : null}

              <InformationAboutJob
                title={"Compensation & Other Benefits"}
                content={`Salary :  
                            ${jobPost?.minimum_salary
                    ? jobPost?.minimum_salary &&
                      !jobPost?.maximum_salary
                      ? jobPost?.minimum_salary
                      : `${jobPost?.minimum_salary} - ${jobPost?.maximum_salary
                      } ${jobPost?.salary_currency
                        ? jobPost?.salary_currency.name
                        : "Taka"
                      }/${jobPost?.salary_type?.type
                        ? jobPost?.salary_type?.type
                        : "Monthly"
                      } `
                    : "Negotiable"
                  }`}
              />

              {jobPost?.benefits ? (
                <InformationAboutJob title={""} content={"Benefits"} />
              ) : null}

              {jobPost?.benefits
                ? jobPost?.benefits.map((benefit, index) => {
                  return (
                    <>
                      <List key={index} dense={true}>
                        <ListItem sx={{ paddingLeft: 0 }}>
                          <ListItemText
                            primary={benefit.job_benefit_options.title}
                            secondary={benefit.job_benefit_categories.title}
                          />
                        </ListItem>
                      </List>
                    </>
                  );
                })
                : ""}

              {jobPost?.job_type?.name ? (
                <InformationAboutJob
                  title={"Employment Status"}
                  content={jobPost?.job_type?.name}
                />
              ) : null}

              {jobPost?.is_cxo ? (
                <>
                  <Typography
                    variant="h6"
                    align="left"
                    sx={{
                      color: "#00457C",
                      fontFamily: "Poppins",
                      fontWeight: 700,
                      fontSize: "16.8px",
                      lineHeight: "25.2px",
                      marginBottom: 2,
                      "@media (max-width: 600px)": {
                        fontSize: "16px",
                      },
                    }}
                  >
                    Job Location
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "18px",
                      fontWeight: 700,
                      filter: jobPost?.is_cxo === 0 ? "none" : "blur(5px)",
                      userSelect: jobPost?.is_cxo === 0 ? "auto" : "none",
                    }}
                  >
                    {jobPost?.job_address}
                  </Typography>
                </>
              ) : null}
              {jobPost?.job_address && jobPost?.is_cxo === 0 ? (
                <InformationAboutJob
                  title={"Job Location"}
                  content={jobPost?.job_address}
                />
              ) : null}
            </Box>
          </Box>

          {/* "See More" Button */}
          <Typography
            variant="body1"
            sx={{
              display: { xs: "flex", md: "none" },
              justifyContent: "flex-start",
              alignItems: "center",
              columnGap: 1,
              color: "#00457C",
              textDecoration: "underline",
              cursor: "pointer",
              fontFamily: "Poppins",
              fontWeight: 500,
              fontSize: "14.7px",
              alignSelf: "flex-start",
              lineHeight: "22.05px",
              marginTop: 2,
            }}
            onClick={toggleExpansion}
          >
            {isExpanded ? "Show less" : "Show more"}

            <svg
              width="15"
              height="9"
              viewBox="0 0 15 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                transform: isExpanded ? 'rotate(180deg)' : 'rotate(0)',
                transition: 'transform 0.3s ease'
              }}
            >
              <path
                d="M1.2998 2.0224L7.41469 7.77759L13.5296 2.0224"
                stroke="#00457C"
                stroke-width="2.1582"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Typography>
        </Box>
      </Card>

      <ApplyToJobModal
        open={openApply}
        jobInfo={jobPost}
        handleClose={() => setOpenApply(false)}
      />
      <OpenAppComponent />
    </>
  );
}

function ShareJobCard(props) {
  const [responseMessage, setResponseMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [open, setOpen] = useState(false);
  const [onHover, setOnHover] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleOnHoverTooltipClose = () => {
    setOnHover(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 1500); // Close the tooltip after 2000 milliseconds (adjust as needed)
  };

  const handleIconClick = (link) => {
    const url = link ? link.toString() : "";

    if (url) {
      const hasHttp = url.startsWith("http://");
      const hasHttps = url.startsWith("https://");

      if (hasHttp || hasHttps) {
        // The string contains either 'http://' or 'https://'
        window.open(url, "_blank"); // Open in a new tab
      } else {
        // The string does not contain 'http://' or 'https://'
        window.open(`http://${url}`, "_blank"); // Open in a new tab
      }
    } else {
      console.error("Invalid URL: web is not defined");
    }
  };

  const socialLinkIconComponent = (socialLink) => {
    const socialIcons = {
      Facebook: (
        <FacebookIcon
          fontSize="large"
          sx={{
            backgroundColor: "#3b5998",
            color: "white",
            padding: 0.5,
            borderRadius: "5px",
            cursor: socialLink?.pivot?.link ? "pointer" : "default", // Set cursor based on link availability
          }}
          onClick={() => handleIconClick(socialLink?.pivot?.link)}
        />
      ),
      LinkedIn: (
        <LinkedInIcon
          fontSize="large"
          sx={{
            backgroundColor: "#0a66c2",
            color: "white",
            padding: 0.5,
            borderRadius: "5px",
            cursor: socialLink?.pivot?.link ? "pointer" : "default",
          }}
          onClick={() => handleIconClick(socialLink?.pivot?.link)}
        />
      ),
      Instagram: (
        <InstagramIcon
          fontSize="large"
          sx={{
            background:
              "linear-gradient(90deg, #feda75, #fa7e1e, #d62976, #962fbf, #4f5bd5)",
            color: "white",
            padding: 0.5,
            borderRadius: "5px",
            cursor: socialLink?.pivot?.link ? "pointer" : "default",
          }}
          onClick={() => handleIconClick(socialLink?.pivot?.link)}
        />
      ),
      Twitter: (
        <TwitterIcon
          fontSize="large"
          sx={{
            backgroundColor: "#3b5998",
            color: "white",
            padding: 0.5,
            borderRadius: "5px",
            cursor: socialLink?.pivot?.link ? "pointer" : "default", // Set cursor based on link availability
          }}
          onClick={() => handleIconClick(socialLink?.pivot?.link)}
        />
      ),
      Link: (
        <LinkIcon
          fontSize="large"
          sx={{
            background: "#178bf4",
            color: "white",
            padding: 0.5,
            borderRadius: "5px",
            cursor: socialLink?.pivot?.link ? "pointer" : "default",
          }}
          onClick={() => handleIconClick(socialLink?.pivot?.link)}
        />
      ),
    };

    return socialIcons[socialLink.name] || null;
  };

  // Copy Profile Url
  function copyUrl() {
    const currentUrl = window.location.href;

    try {
      navigator.clipboard
        .writeText(currentUrl)
        .then(() => {
          setResponseMessage("URL copied");
          setOpenAlert(true);
          // You can setResponseMessage and setOpenAlert here if needed
        })
        .catch((err) => {
          console.error("Error copying URL to clipboard:", err);
          // Handle the error if necessary
        });
    } catch (err) {
      console.error("Error copying URL to clipboard:", err);
      // Handle the error if necessary
    }
  }

  return (
    <>
      <Card
        sx={{
          border: "1px solid #FFFFFF",
          borderRadius: "10px",
          marginTop: 6,
        }}
      >
        <Box
          className="published_on"
          sx={{
            px: { xs: 2, sm: 4 },
            pt: 2,
            pb: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Epilogue",
              fontWeight: 700,
              fontSize: "16.5px",
              lineHeight: "18.45px",
              color: "#424447",
            }}
          >
            Published On
          </Typography>
          <Typography
            sx={{
              fontFamily: "Epilogue",
              fontWeight: 700,
              fontSize: "16.5px",
              lineHeight: "18.45px",
              color: "#424447",
            }}
          >
            Share This Job
          </Typography>
        </Box>

        <Divider />

        <Box
          sx={{
            px: { xs: 2, sm: 4 },
            py: 3,
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: { xs: "flex-start", sm: "space-between" },
            alignItems: { xs: "start", sm: "center" },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: 500,
              fontSize: "14.7px",
              lineHeight: "22.05px",
              color: "#72737C",
            }}
          >
            {moment(props?.jobPublished).format("DD MMMM, YYYY")}
          </Typography>

          {props?.isCxo ? (
            <Typography
              variant="h6"
              sx={{
                fontSize: "18px",
                fontWeight: 700,
                filter: props?.isCxo === 0 ? "none" : "blur(5px)",
                userSelect: props?.isCxo === 0 ? "auto" : "none",
              }}
            >
              {moment(props?.jobPublished).format("DD MMMM, YYYY")}
            </Typography>
          ) : (
            <Stack
              direction={"row"}
              spacing={1}
              sx={{
                justifyContent: { xs: "flex-start", sm: "flex-end" },
                marginTop: { xs: 1, sm: 0 },
              }}
            >
              {props.socialLinks?.map((socialLink, index) => (
                <React.Fragment key={index}>
                  {socialLinkIconComponent(socialLink)}
                </React.Fragment>
              ))}

              {/* Tooltip handled for Before and After copy event */}
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <LightTooltip
                  onClose={handleTooltipClose}
                  open={open}
                  title={onHover ? "Copy URL" : "Copied"}
                  onMouseEnter={() => [setOnHover(true), handleTooltipOpen()]}
                >
                  <LinkIcon
                    sx={{
                      background: "#178bf4",
                      color: "white",
                      padding: 1,
                      width: "35px",
                      height: "35px",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => [
                      copyUrl(),
                      handleTooltipOpen(),
                      handleOnHoverTooltipClose(),
                    ]}
                  />
                </LightTooltip>
              </ClickAwayListener>
            </Stack>
          )}
        </Box>
      </Card>

      <Card
        sx={{
          border: "1px solid #FDB5B3",
          borderRadius: "10px",
          marginTop: 6,
          marginBottom: 3,
          backgroundColor: "#ffefef",
        }}
      >
        <Box
          component="div"
          sx={{
            px: { xs: 2, sm: 4 },
            pt: 2,
            pb: 1,
            color: "#5c5c5c",
          }}
        >
          <Typography component="p" sx={{
            fontWeight: 700,
            fontSize: "16.5px",
            lineHeight: "18.45px",
            color: "rgb(2, 117, 216);",
          }}>প্রতারকদের থেকে সাবধান!</Typography>
          <Box component="div" sx={{ py: "18px" }}>
            <Typography component="span" sx={{ color: 'rgb(2, 117, 216);' }}>
              atbjobs.com
            </Typography>{" "}
            কোনো অর্থের বিনিময়ে চাকরি পাওয়া বা ইন্টারভিউয়ের নিশ্চয়তা প্রদান
            করে না। প্রতারণাকারীরা নিবন্ধন ফি, ফেরতযোগ্য ফি, বা এ ধরনের অজুহাতে
            অর্থ দাবি করতে পারে—এই ধরনের প্রস্তাব থেকে সতর্ক থাকুন।
          </Box>
          <Typography component="p" sx={{ pb: "18px" }}>
            আপনি যদি এ ধরনের কোনো সন্দেহজনক ইমেইল বা বার্তা পান, তাহলে আমাদের
            সাথে দ্রুত যোগাযোগ করুন।
          </Typography>

          <Typography component="p" sx={{ fontWeight: 'bold' }}>
            ফোন :{" "}
            <Typography component="span" sx={{ color: "rgb(2, 117, 216)" }}>
              +8801400492818
            </Typography>
          </Typography>
          <Typography component="p" sx={{ pt: "3px", fontWeight: 'bold' }} >
            ইমেইল :{" "}
            <Typography component="span" sx={{ color: "rgb(2, 117, 216)" }}>
              contact@atb-jobs.com
            </Typography>
          </Typography>
        </Box>
      </Card>
    </>
  );
}

function AboutCompanyCard(props) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { followEmployerIds, settingCandidateData, candidateData } = useContext(
    CandidateContext
  );
  const { userData } = useContext(UserContext);

  const [openAlert, setOpenAlert] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const history = useHistory();

  const unSaveEmployer = (candId, empId) => {
    setSaveLoading(false);
    setLoading(true);

    unFollowCompany(candId, empId)
      .then((response) => {
        if (response && response.success) {
          settingCandidateData();
          setResponseMessage(response.response.message);
          setOpenAlert(true);
        } else {
          // Handle failure, show error message or take appropriate action
          console.log(response);
        }
      })
      .catch((error) => {
        // Handle errors, show error message or take appropriate action
        console.log(error);
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  const saveEmployer = (candId, empId) => {
    if (userData) {
      setLoading(false);
      setSaveLoading(true);

      followCompany(candId, empId)
        .then((response) => {
          if (response && response.success) {
            settingCandidateData();
            setResponseMessage(response.response.message);
            setOpenAlert(true);
          } else {
            // Handle failure, show error message or take appropriate action
            console.log(response);
          }
        })
        .catch((error) => {
          // Handle errors, show error message or take appropriate action
          console.log(error);
        })
        .finally(() => {
          // setLoading(false);
        });
    } else {
      history.push("/login");
    }
  };

  // Parsing HTML from Rich Editor data
  const introHtml = props.jobData?.employer?.intro;
  const parser = new DOMParser();
  const doc = parser.parseFromString(introHtml, "text/html");
  const introPlainText = doc.body.textContent || "";

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      {props?.jobData?.is_cxo ? (
        <Card
          sx={{
            display: "flex",
            // height: '100%',
            width: "100%",
            borderRadius: "10px",
            justifyContent: "center",
            margin: "0px",
            padding: 2,
            border: "1px solid #C4C4C4",
          }}
        >
          <img src="/images/cxo/blurred-sidebar.png" />
        </Card>
      ) : (
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            borderRadius: "10px",
            border: "1px solid #C4C4C4",
            boxShadow: 3,
          }}
        >
          <Box
            sx={{
              px: 3,
              pt: 4,
              pb: 3,
              display: "flex",
              flexDirection: "column",
              rowGap: 1,
            }}
          >
            {props?.jobData?.employer?.profile_pic_base64 ? (
              <Tooltip title="Go to Profile">
                <Link to={`/employer/profile/${props.jobData?.employer?.slug}`}>
                  <EmployerProfilePicture
                    profile_picture={props.jobData.employer?.profile_pic_base64}
                    height={64}
                    width={64}
                  />
                </Link>
              </Tooltip>
            ) : (
              <img
                alt="Company Avatar"
                src={"/images/employer/employer-building.png"}
                width="64px"
                height="64px"
              />
            )}

            <Link to={`/employer/profile/${props?.jobData?.employer?.slug}`}>
              <Typography
                variant="h5"
                align="left"
                sx={{
                  mt: 1,
                  fontFamily: "Epilogue",
                  fontWeight: 700,
                  fontSize: "18px",
                  lineHeight: "18.45px",
                  color: "#00457C",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                {props?.jobData?.employer?.employer_name}
              </Typography>
            </Link>

            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: 500,
                fontSize: "14.7px",
                lineHeight: "22.05px",
                color: "#605C78",
              }}
            >
              {props.jobData?.employer?.map_location
                ? props.jobData?.employer?.map_location
                : ""}
            </Typography>
          </Box>

          {props?.jobData?.employer?.intro ? (
            <>
              <Divider />

              <Box
                sx={{
                  px: 3,
                  pt: 2,
                  pb: 3,
                  display: "flex",
                  flexDirection: "column",
                  rowGap: 1,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: 700,
                    fontSize: "16.8px",
                    lineHeight: "25.2px",
                    color: "#00457C",
                  }}
                >
                  About the Company
                </Typography>
                <Box
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: 400,
                    fontSize: "14.7px",
                    lineHeight: "22.05px",
                    color: "#605C78",
                    overflow: "hidden",
                    maxHeight: isExpanded ? "none" : "88px",
                    transition: "max-height 0.3s ease",
                  }}
                >
                  {props?.jobData?.employer?.intro ? introPlainText : ""}
                </Box>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: 600,
                    fontSize: "14.7px",
                    lineHeight: "22.05px",
                    color: "#00457C",
                    cursor: "pointer",
                  }}
                  onClick={toggleExpansion}
                >
                  {isExpanded ? "Read less" : "Read more"}
                </Typography>
              </Box>
            </>
          ) : null}

          <Divider />

          <Box
            sx={{
              px: 3,
              pt: 2,
              pb: 3,
              display: "flex",
              flexDirection: { xs: "row", md: "column", xl: "row" },
              justifyContent: {
                xs: "space-between",
                md: "flex-start",
                xl: "space-between",
              },
              alignItems: { xs: "center", md: "flex-start", xl: "center" },
              gap: 2,
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: "14.7px",
                lineHeight: "22.05px",
                color: "#605C78",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => props.onTabChange(null, "opening")}
            >
              Job Openings
              <svg
                width="22"
                height="23"
                viewBox="0 0 22 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.8082 8.68903C17.9774 8.13437 17.6649 7.54756 17.1102 7.37836L8.07142 4.62111C7.51675 4.45191 6.92994 4.7644 6.76075 5.31906C6.59155 5.87373 6.90403 6.46054 7.4587 6.62974L15.4932 9.08062L13.0423 17.1151C12.8731 17.6698 13.1856 18.2566 13.7403 18.4258C14.2949 18.595 14.8817 18.2825 15.0509 17.7278L17.8082 8.68903ZM5.58923 15.5442L17.2974 9.30946L16.3103 7.45589L4.60218 13.6906L5.58923 15.5442Z"
                  fill="#00457C"
                />
              </svg>
            </Typography>

            {userData?.user_role_id !== 3 ? (
              followEmployerIds.includes(props?.jobData?.employer?.id) ? (
                <LoadingButton
                  loading={loading}
                  size="large"
                  variant="contained"
                  onClick={() =>
                    unSaveEmployer(
                      candidateData.id,
                      props?.jobData?.employer?.id
                    )
                  }
                  sx={{
                    backgroundColor: "#E5F5FF",
                    borderRadius: "60px",
                    color: "white",
                    padding: "10px 18px",
                    boxShadow: 0,
                    minWidth: "100px",
                    minHeight: "40px",
                    "&:hover": {
                      backgroundColor: "#E5F5FF",
                    },
                  }}
                >
                  {!loading && (
                    <span style={{ color: "#0079C1" }}>Unfollow</span>
                  )}
                </LoadingButton>
              ) : (
                <LoadingButton
                  loading={saveLoading}
                  size="large"
                  variant="contained"
                  onClick={() =>
                    saveEmployer(candidateData.id, props?.jobData?.employer?.id)
                  }
                  sx={{
                    backgroundColor: "#E5F5FF",
                    borderRadius: "60px",
                    color: "white",
                    padding: "10px 18px",
                    boxShadow: 0,
                    minWidth: "100px",
                    minHeight: "40px",
                    "&:hover": {
                      backgroundColor: "#E5F5FF",
                    },
                  }}
                >
                  {!saveLoading && (
                    <>
                      <AddCircleOutlineIcon
                        style={{ color: "#0079C1", marginRight: "8px" }}
                      />
                      <span style={{ color: "#0079C1" }}>Follow</span>
                    </>
                  )}
                </LoadingButton>
              )
            ) : (
              ""
            )}
          </Box>
        </Card>
      )}

      <SuccessAlert
        open={openAlert}
        message={responseMessage}
        onHide={() => setOpenAlert(false)}
      />
    </>
  );
}

function SimilarJobCard(props) {
  const { savedJobIds, candidateData, settingCandidateData } = useContext(
    CandidateContext
  );
  const { userData, isLoggedin } = useContext(UserContext);

  const [loadingStates, setLoadingStates] = useState({});

  const history = useHistory();

  const unSaveCandidateJob = async (candId, jobId, index) => {
    if (isLoggedin) {
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        [index]: true,
      }));

      await deleteCandidateSaveJobInfo(candId, jobId)
        .then((response) => {
          if (response && response.success) {
            settingCandidateData();
            setLoadingStates((prevLoadingStates) => ({
              ...prevLoadingStates,
              [index]: false,
            }));
          } else {
            // Handle failure, show error message or take appropriate action
            console.log(response);
          }
        })
        .catch((error) => {
          // Handle errors, show error message or take appropriate action
          console.log(error);
        })
        .finally(() => {
          setLoadingStates((prevLoadingStates) => ({
            ...prevLoadingStates,
            [index]: false,
          }));
        });
    } else {
      history.push("/login");
    }
  };

  const saveCandidateJob = async (candId, jobId, index) => {
    if (isLoggedin) {
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        [index]: true,
      }));

      await postCandidateSaveJobInfo(candId, jobId)
        .then((response) => {
          if (response && response.success) {
            settingCandidateData();
            setLoadingStates((prevLoadingStates) => ({
              ...prevLoadingStates,
              [index]: false,
            }));
          } else {
            // Handle failure, show error message or take appropriate action
            if (
              !userData &&
              response.errors[0].message === "Unauthenticated."
            ) {
              history.push("/login");
            } else {
              console.log(response);
            }
          }
        })
        .catch((error) => {
          // Handle errors, show error message or take appropriate action
          console.log(error);
        })
        .finally(() => {
          setLoadingStates((prevLoadingStates) => ({
            ...prevLoadingStates,
            [index]: false,
          }));
        });
    } else {
      history.push("/login");
    }
  };

  return (
    <Card
      sx={{
        mt: 4,
        display: "flex",
        flexDirection: "column",
        borderRadius: "10px",
        border: "1px solid #C4C4C4",
        boxShadow: 3,
      }}
    >
      <Typography
        sx={{
          px: 3,
          pt: 4,
          pb: 3,
          color: "#424447",
          fontFamily: "Epilogue",
          fontWeight: 700,
          fontSize: "18px",
          lineHeight: "18.45px",
        }}
      >
        Similar Jobs <span style={{ color: "#0079C1" }}>({props.count})</span>
      </Typography>

      <Divider />

      {props?.similarJobs?.map((data, index) => {
        return (
          <Box sx={{ px: 3, pt: 4, pb: 3 }} key={index}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                columnGap: 1,
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
                <Link to={`/jobs/${data?.post_id}/${data?.slug}`}>
                  <Typography
                    sx={{
                      fontFamily: "Epilogue",
                      fontWeight: 600,
                      fontSize: "18px",
                      lineHeight: "21.6px",
                      color: "#0079C1",
                    }}
                  >
                    {data.job_title}
                  </Typography>
                </Link>

                <Typography
                  sx={{
                    fontFamily: "Epilogue",
                    fontWeight: 600,
                    fontSize: "16px",
                    lineHeight: "19.2px",
                    color: "#72737C",
                  }}
                >
                  {data.is_cxo ? (
                    candidateData?.is_cxo_eligible ||
                      userData?.id === data.employer?.user_id ? (
                      data.employer?.employer_name
                    ) : (
                      <img
                        src="/images/cxo/blurred-text.png"
                        style={{ width: "100%" }}
                      />
                    )
                  ) : (
                    data.employer?.employer_name
                  )}
                </Typography>
              </Box>

              {/* Save Button */}
              {savedJobIds?.includes(data?.post_id) ? (
                <Tooltip title="Saved" placement="top" arrow>
                  <LoadingButton
                    loading={loadingStates[index]}
                    onClick={() =>
                      unSaveCandidateJob(candidateData.id, data?.post_id)
                    }
                    aria-label="Unsave"
                    component={IconButton}
                    sx={{
                      flexWrap: "nowrap",
                      fontSize: "0.9375rem",
                      boxShadow: 0,
                      lineHeight: "0px",
                      height: "40px",
                      ":focus": { outline: "none" },
                      padding: "0px !important",
                      minWidth: "40px",
                    }}
                    variant="outlined"
                    size="small"
                  >
                    <FavoriteIcon style={{ color: "red" }} />
                  </LoadingButton>
                </Tooltip>
              ) : (
                <Tooltip title="Save Job" placement="top" arrow>
                  <LoadingButton
                    loading={loadingStates[index]}
                    onClick={() =>
                      saveCandidateJob(
                        candidateData.id,
                        { job_post_id: data.post_id },
                        index
                      )
                    }
                    aria-label="Save"
                    component={IconButton}
                    sx={{
                      flexWrap: "nowrap",
                      fontSize: "0.9375rem",
                      boxShadow: 0,
                      lineHeight: "0px",
                      height: "40px",
                      ":focus": { outline: "none" },
                      padding: "0px !important",
                      minWidth: "40px",
                    }}
                    variant="outlined"
                    size="small"
                  >
                    <FavoriteBorderIcon />
                  </LoadingButton>
                </Tooltip>
              )}
            </Box>

            <Typography
              sx={{
                mt: 3,
                fontFamily: "Epilogue",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "22.4px",
                color: "#424447",
              }}
            >
              Salary:
              {data.minimum_salary && data.negotiable === 0
                ? ` ${data.minimum_salary} - ${data.maximum_salary} ${data.salary_currency ? data.salary_currency : "Taka"
                }/${data.salary_type ? data.salary_type?.type : "Monthly"}`
                : " Negotiable"}
            </Typography>
          </Box>
        );
      })}
    </Card>
  );
}

function SingleJobInformation(props) {
  return props.loading ? (
    <GlobalLoader height="70vh" />
  ) : (
    <Box sx={{ px: { xs: 0, md: 8 } }}>
      <CandidateContextProvider>
        <Grid container spacing={3}>
          <Grid item xs={12} md={9}>
            <JobInformationCard jobPost={props?.jobDetails?.job_post} />
            <ShareJobCard
              socialLinks={props?.jobDetails?.employer_info?.social_networks}
              jobPublished={props?.jobDetails?.job_post?.post_date}
              isCxo={props?.jobDetails?.job_post?.is_cxo}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <AboutCompanyCard
              jobData={props?.jobDetails?.job_post}
              onTabChange={props.onTabChange}
            />
            <SimilarJobCard
              similarJobs={props?.jobDetails?.similar_jobs}
              count={props?.jobDetails?.total_similar_jobs}
            />
          </Grid>
        </Grid>
      </CandidateContextProvider>
    </Box>
  );
}

export default SingleJobInformation;
