import React, { useEffect, useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./CandidateDashboardSideBar.css";
import { CandidateContext } from "../../../context/CandidateContext";
import { UserContext } from "../../../context/UserContext";
import { Box, Card, Typography } from "@mui/material";
import CandidateDashboardDesignation from "./CandidateDashboardDesignation";
import CandidateDashboardAvatar from "./CandidateDashboardAvatar";



function CandidateDashboardSidebar() {
  const location = useLocation();
  const { candidateData, settingCandidateData } = useContext(CandidateContext);
  const { logout } = useContext(UserContext);

  useEffect(() => {
    if (Object.keys(candidateData).length === 0) {
      settingCandidateData();
    }
  }, []);

  const menuItems = [
    {
      name: "Dashboard",
      to: "/candidate-dashboard",
      src: "/v2/icons/dashboard/dashboard-active.png",
      srcActive: "/v2/icons/dashboard/dashboard.png",
    },
    {
      name: "Profile",
      to: "/candidate-update-profile",
      src: "/v2/icons/dashboard/profile.png",
      srcActive: "/v2/icons/dashboard/profile-active.png",
    },
    {
      name: "CV Manager",
      to: "/cv-manager",
      src: "/v2/icons/dashboard/cv-manager.png",
      srcActive: "/v2/icons/dashboard/cv-manager-active.png",
    },
    {
      name: "Applied Jobs",
      to: "/applied-jobs",
      src: "/v2/icons/dashboard/applied-job.png",
      srcActive: "/v2/icons/dashboard/applied-job-active.png",
    },
    {
      name: "Saved jobs",
      to: "/saved-jobs",
      src: "/v2/icons/dashboard/saved-job.png",
      srcActive: "/v2/icons/dashboard/saved-job-active.png",
    },
    {
      name: "Followed Company",
      to: "/followed-companies",
      src: "/v2/icons/dashboard/followed-company.png",
      srcActive: "/v2/icons/dashboard/followed-company-active.png",
    },
    // {
    //   name: "Profile Viewed",
    //   to: `#`,
    //   src: "/v2/icons/dashboard/profile-viewed.svg",
    //   srcActive: "/v2/icons/dashboard/profile-viewed-active.svg",
    // },
    // {
    //   name: "Recommended Jobs",
    //   to: "#",
    //   src: "/v2/icons/dashboard/recommended-job.svg",
    //   srcActive: "/v2/icons/dashboard/recommended-job-active.svg",
    // },
    // {
    //   name: "Direct Recruiting",
    //   to: "/dr-inbox",
    //   src: "/v2/icons/dashboard/message.png",
    //   srcActive: "/v2/icons/dashboard/message-active.png",
    // },
    {
      name: "Message",
      to: "/candidate-message",
      src: "/v2/icons/dashboard/message.png",
      srcActive: "/v2/icons/dashboard/message-active.png",
    },
    {
      name: "Get Job Alert",
      to: "/candidate-notifications",
      src: "/v2/icons/dashboard/alert.png",
      srcActive: "/v2/icons/dashboard/alert-active.png",
    },
    {
      name: "Settings",
      to: "/candidate-change-password",
      src: "/v2/icons/dashboard/setting.png",
      srcActive: "/v2/icons/dashboard/setting-active.png",
    },
  ];

  const isActive = (item) => {
    if (location.pathname === '/generate-resume' && item.to === '/cv-manager') {
      return true;
    }
    return location.pathname === item.to;
  };

  return (
    <Box sx={{
      position: 'sticky',
      top: '97px',
      height: 'calc(100vh - 97px)',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Card sx={{ background: "#FFFFFF", display: 'flex', flexDirection: 'column', height: "100%", overflow: 'hidden' }}>
        <Box sx={{ marginTop: '30px' }}><CandidateDashboardAvatar /></Box>
        <Box sx={{ borderBottom: "1px solid #E8E8E8", mb: "20px", }}>
          <CandidateDashboardDesignation />
        </Box>


        {/* Menu starts */}
        <Box
          sx={{
            paddingBottom: "20px",
            flexGrow: 1,
            overflowY: 'auto'
          }}
        >
          {menuItems.map((item) => (
            <NavLink to={item.to}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  alignContent: "center",
                  paddingX: "0.5rem",
                  paddingY: "0.5rem",
                  background: isActive(item) ? "#E5F5FF" : "#FFFFFF",
                  borderLeft: isActive(item) ? "5px solid #0079C1" : "5px solid #FFFFFF",
                }}
              >
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={isActive(item) ? item.srcActive : item.src}
                  alt=""
                />
                <Typography
                  sx={{ fontWeight: 600, fontSize: "16px" }}
                  color={isActive(item) ? "#0079C1" : "#424447"}
                  className="ml-2"
                >
                  {item.name}
                </Typography>
              </Box>
            </NavLink>
          ))}
          <NavLink to="/" onClick={() => logout()}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                alignContent: "center",
                paddingX: "0.5rem",
                paddingY: "0.5rem",
                // marginY: "0.5rem",
                background: "#FFFFFF",
                borderLeft: "5px solid #FFFFFF",
              }}
            >
              <img
                style={{ width: "40px", height: "40px" }}
                src="/v2/icons/dashboard/logout.png"
                alt="logout icon"
              />
              <Typography
                sx={{ fontWeight: 600, fontSize: "16px" }}
                color="#424447"
                className="ml-2"
              >
                Logout
              </Typography>
            </Box>
          </NavLink>
        </Box>
      </Card>
    </Box>
  );
}

export default React.memo(CandidateDashboardSidebar);
